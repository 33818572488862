import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { useParams } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import toast from "react-hot-toast";
import { ethers } from "ethers";
import nftContractAbi from "../assets/Abis/escrow-abi.json";
import Layout from "../components/Escrow/Layout";
import NavBar from "../components/Escrow/NavBar";
import { getHumanReadableMsg, genRandomChannelId } from "../utils/utils";
import { web3ModalContext } from "../components/Web3ModalProvider";
import getContractsAddress from "../web3/contractsAddress";

export default function EscrowProduct() {
  const { web3Modal, web3Data, setWeb3Data, connectWallet, getContract } = useContext(web3ModalContext);
  const escrowAddress = getContractsAddress(web3Data.chainId).escrowAddress;

  const params = useParams();
  const tradeId = params?.tradeId;
  console.log(tradeId);

  const [visitorType, setVisitorType] = useState(0); // 0: observer, 1: buyer, 2: seller
  const [isTradeCompleted, setIsTradeCompleted] = useState(false);
  const [myTradeId, setMyTradeId] = useState(tradeId === undefined ? 0 : tradeId);

  const [channelIdValue, setChannelIdValue] = useState("");
  const [chatRoomNumberValue, setChatRoomNumberValue] = useState("");
  const [productNameValue, setProductNameValue] = useState("");
  const [productPriceValue, setProductPriceValue] = useState("");
  const [productSellerWalletValue, setProductSellerWalletValue] = useState("");
  const [productLinkValue, setProductLinkValue] = useState("");

  const connect = async () => {
    try {
      const { provider, library, signer, account, network, chainId } = await connectWallet();
      setWeb3Data({ ...web3Data, provider, library, signer, account, connected: true, network, chainId });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (web3Modal?.cachedProvider) {
      connect();
    }
  }, []);

  useEffect(() => {
    if (web3Data.connected) {
      if (web3Data.signer) {
        decideVisitorType().then(({ type, trade }) => {
          if (tradeId && type === 1) {
            setChatRoomNumberValue(trade.chatRoomNumber.toString());
            setProductNameValue(trade.productName.toString());
            setProductPriceValue(trade.price.toString());
            setProductSellerWalletValue(trade.seller.toString());
            setProductLinkValue(trade.productLink.toString());
          }
          if (tradeId && type === 2) {
            setChatRoomNumberValue(trade.chatRoomNumber.toString());
            setProductNameValue(trade.productName.toString());
            setProductPriceValue(trade.price.toString());
            setProductSellerWalletValue(trade.seller.toString());
            setProductLinkValue(trade.productLink.toString());
          }
        });    
      }
    }
  }, [web3Data.connected]);

  const handleChannelIdChange = (event) => {
    setChannelIdValue(event.target.value);
  };

  const handleChatRoomNumberValueChange = (event) => {
    setChatRoomNumberValue(event.target.value);
  };

  const handleProductNameChange = (event) => {
    setProductNameValue(event.target.value);
  };

  const handleProductPriceChange = (event) => {
    setProductPriceValue(event.target.value);
  };

  const handleProductSellerWalletChange = (event) => {
    setProductSellerWalletValue(event.target.value);
  };

  const handleProductLinkChange = (event) => {
    setProductLinkValue(event.target.value);
  };

  const decideVisitorType = async () => {
    let type = 0;
    let trade = undefined;
    if (tradeId) {
      const contract = getContract(escrowAddress, nftContractAbi);
      trade = await contract.productTrades(tradeId);
      if (trade.buyer === web3Data.account) type = 1;
      else if (trade.seller === web3Data.account) type = 2;
      else type = 0;
      if (trade.currentState === 2) setIsTradeCompleted(true);
    }
    setVisitorType(type);
    return { type, trade };
  };

  const validateInputs = () => {
    let isValid = true;
    if (chatRoomNumberValue.length <= 0) {
      isValid = false;
      toast.error("Please input the chat room number");
      return;
    }

    if (productNameValue.length <= 0) {
      isValid = false;
      toast.error("Please input the product name");
      return;
    }

    if (parseInt(productPriceValue) <= 0) {
      isValid = false;
      toast.error("Please input valid number for the price");
      return;
    }

    // validate seller's wallet address
    if (!ethers.utils.isAddress(productSellerWalletValue)) {
      isValid = false;
      toast.error("The seller's wallet address is not valid");
      return;
    }
    return isValid;
  };

  const handleCreateNewTrade = async () => {
    if (!web3Data.connected) {
      toast.error("Connect the wallet first");
      return;
    }

    const isValid = validateInputs();
    if (!isValid) return;

    const contract = getContract(escrowAddress, nftContractAbi);
    await contract.createNewTrade(
      chatRoomNumberValue,
      productNameValue,
      productPriceValue,
      productSellerWalletValue,
      0,
      0,
      {
        value: productPriceValue,
      }
    )
      .then((tx) => {
        return tx.wait().then(
          async (receipt) => {
            console.log(receipt);
            // This is entered if the transaction receipt indicates success
            toast.success("Successfully submitted!");
            setVisitorType(1); // buyer
            return true;
          },
          (error) => {
            console.log(error);
            toast.error("Transaction Failed!");
          }
        );
      })
      .catch((error) => {
        console.error("Transaction Failed!");
        if (error.message.indexOf("signature") > 0) {
          toast.error("You canceled transaction!");
        } else {
          toast.error(getHumanReadableMsg(error));
        }
      });
  };

  const handleDeliverProduct = async () => {
    if (!web3Data.connected) {
      toast.error("Connect the wallet first");
      return;
    }

    const contract = getContract(escrowAddress, nftContractAbi);
    await contract.deliverProduct(tradeId, productLinkValue, 0)
      .then((tx) => {
        return tx.wait().then(
          async (receipt) => {
            console.log(receipt);
            // This is entered if the transaction receipt indicates success
            toast.success("Successfully submitted!");
            return true;
          },
          (error) => {
            console.log(error);
            toast.error("Transaction Failed!");
          }
        );
      })
      .catch((error) => {
        console.error("Transaction Failed!");
        if (error.message.indexOf("signature") > 0) {
          toast.error("You canceled transaction!");
        } else {
          toast.error(getHumanReadableMsg(error));
        }
      });
  };

  const handleReleaseFunds = async () => {
    if (!web3Data.connected) {
      toast.error("Connect the wallet first");
      return;
    }

    const contract = getContract(escrowAddress, nftContractAbi);
    await contract.releaseFunds(myTradeId, 0)
      .then((tx) => {
        return tx.wait().then(
          async (receipt) => {
            console.log(receipt);
            // This is entered if the transaction receipt indicates success
            toast.success("Successfully submitted!");
            return true;
          },
          (error) => {
            console.log(error);
            toast.error("Transaction Failed!");
          }
        );
      })
      .catch((error) => {
        console.error("Transaction Failed!");
        if (error.message.indexOf("signature") > 0) {
          toast.error("You canceled transaction!");
        } else {
          toast.error(getHumanReadableMsg(error));
        }
      });
  };

  const handleAppeal = async () => {
    if (!web3Data.connected) {
      toast.error("Connect the wallet first");
      return;
    }

    const contract = getContract(escrowAddress, nftContractAbi);
    await contract.appeal(myTradeId, 0)
      .then((tx) => {
        return tx.wait().then(
          async (receipt) => {
            console.log(receipt);
            // This is entered if the transaction receipt indicates success
            toast.success("Successfully submitted!");
            return true;
          },
          (error) => {
            console.log(error);
            toast.error("Transaction Failed!");
          }
        );
      })
      .catch((error) => {
        console.error("Transaction Failed!");
        if (error.message.indexOf("signature") > 0) {
          toast.error("You canceled transaction!");
        } else {
          toast.error(getHumanReadableMsg(error));
        }
      });
  };

  const handleWithdrawFunds = async () => {
    if (!web3Data.connected) {
      toast.error("Connect the wallet first");
      return;
    }

    const contract = getContract(escrowAddress, nftContractAbi);
    await contract.withdrawFunds(myTradeId, 0)
      .then((tx) => {
        return tx.wait().then(
          async (receipt) => {
            console.log(receipt);
            // This is entered if the transaction receipt indicates success
            toast.success("Successfully submitted!");
            return true;
          },
          (error) => {
            console.log(error);
            toast.error("Transaction Failed!");
          }
        );
      })
      .catch((error) => {
        console.error("Transaction Failed!");
        if (error.message.indexOf("signature") > 0) {
          toast.error("You canceled transaction!");
        } else {
          toast.error(getHumanReadableMsg(error));
        }
      });
  };

  if (web3Modal) {
    web3Data.provider?.once("block", () => {
      const contract = getContract(escrowAddress, nftContractAbi);
      contract.on("NewTradeCreated", (tradeId, category) => {
        console.log(visitorType, tradeId, category, category.toNumber());
        if (category.toNumber() === 0) {
          console.log("NewProductTradeCreated");
          console.log(tradeId);
          setMyTradeId(tradeId.toNumber());
        }
      });

      contract.on("ProductDelivered", (tradeId, productLink, category) => {
        console.log("ProductDelivered");
        console.log(tradeId, myTradeId, productLink, category.toNumber());
        if (tradeId.toNumber() === myTradeId && category.toNumber() === 0 && visitorType === 1) {
          // buyer
          console.log(tradeId, productLink, category.toNumber());
          setProductLinkValue(productLink);
        }
      });

      contract.on("FundReleased", (tradeId, category) => {
        console.log("FundReleased");
        console.log(tradeId);
        if (tradeId.toNumber() === myTradeId && category.toNumber() === 0 && visitorType === 2) {
          /** */
        }
      });
    });
  }

  const createNewChatRoom = async () => {
    const uuid = genRandomChannelId();
    window.open("/escrow/chat/" + uuid, "_blank");
  };

  const joinChatRoom = async () => {
    window.open("/escrow/chat/" + channelIdValue, "_blank");
  };

  return (
    <Layout>
      <NavBar></NavBar>
      <Stack alignItems="center"></Stack>
      <div className="product-wrapper text-white">
        <h2>Trade Product through Escrow</h2>
        <h4>Blockchain escrow is transparent, reliable and fast</h4>
        <h4>
          {visitorType === 2
            ? "We are waiting for you to upload a link to the product"
            : "Make sure you have already agreed with the seller over the chat"}
        </h4>
        <Stack className="chat-input-wrapper margin-top-50 margin-bottom-50">
          <Stack style={{ padding: "5px 5px 0 5px" }}>
            <Button variant="contained" className="create-chat-btn" onClick={() => createNewChatRoom()}>
              Create a new chat room
            </Button>
          </Stack>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            className="margin-5"
            style={{ padding: "0 5px" }}
          >
            <input
              type="text"
              placeholder="Enter Chat Room ID"
              className="chat-room-id"
              value={channelIdValue}
              onChange={handleChannelIdChange}
            />
            <Button
              variant="contained"
              className="goto-chat-btn"
              onClick={() => joinChatRoom()}
              disabled={!channelIdValue}
            >
              Join
            </Button>
          </Stack>
        </Stack>
        <Stack className="product-input-wrapper">
          <div className="input-area">
            <input
              type="text"
              placeholder="Enter Chat Room Number"
              disabled={tradeId ? true : false}
              value={chatRoomNumberValue}
              onChange={handleChatRoomNumberValueChange}
            />
          </div>
          <div className="input-area">
            <input
              type="text"
              placeholder="Enter Product Name"
              disabled={tradeId ? true : false}
              value={productNameValue}
              onChange={handleProductNameChange}
            />
          </div>
          <div className="input-area">
            <input
              type="number"
              min="0"
              placeholder="Enter Price"
              disabled={tradeId ? true : false}
              value={productPriceValue}
              onChange={handleProductPriceChange}
            />
          </div>
          <div className="input-area">
            <input
              type="text"
              placeholder="Enter Seller's Wallet Address"
              disabled={tradeId ? true : false}
              value={productSellerWalletValue}
              onChange={handleProductSellerWalletChange}
            />
          </div>
          <div className="input-area">
            <input
              type="text"
              disabled={visitorType !== 2}
              placeholder={
                tradeId ? "Paste the product link here..." : "You will see the download link to the product soon..."
              }
              value={productLinkValue}
              onChange={handleProductLinkChange}
            />
          </div>
          {visitorType === 2 ? (
            <Stack>
              <Button
                variant="contained"
                className="seller-product-submit-btn"
                onClick={() => handleDeliverProduct()}
                disabled={isTradeCompleted}
              >
                Submit
              </Button>
              <Button
                variant="contained"
                className="seller-product-submit-btn"
                onClick={() => handleWithdrawFunds()}
                disabled={isTradeCompleted}
              >
                Withdraw Funds
              </Button>
            </Stack>
          ) : (
            <Stack>
              <Button
                variant="contained"
                className="seller-product-submit-btn"
                onClick={() => handleCreateNewTrade()}
                disabled={isTradeCompleted}
              >
                Submit
              </Button>
              <Stack direction="row" justifyContent="space-evenly">
                <Button
                  variant="contained"
                  className="seller-product-releasefund-btn"
                  disabled={visitorType === 0 || isTradeCompleted}
                  onClick={() => handleReleaseFunds()}
                >
                  Release Funds
                </Button>
                <Button
                  variant="contained"
                  className="seller-product-releasefund-btn"
                  disabled={visitorType === 0 || isTradeCompleted}
                  onClick={() => handleAppeal()}
                >
                  Appeal To Admin
                </Button>
              </Stack>
            </Stack>
          )}
        </Stack>
      </div>
    </Layout>
  );
}
