export const stats = [
  {
    className: "marketcap",
    title: "Marketcap",
    value: 24870000,
    unit: "",
  },
  {
    className: "supply",
    title: "Circulating Supply",
    value: 1224875,
    unit: "$",
  },
  {
    className: "holder",
    title: "Holders",
    value: 82000,
    unit: "",
  },
  {
    className: "price",
    title: "AETERNA PRICE",
    value: 500.28,
    unit: "$",
  },
];
