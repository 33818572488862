import { useEffect, useState } from "react";
import { MdHistory, MdSettings, MdClose } from "react-icons/md";
import ModalWrap from "../Modal";

export default function SwapHeader({ slippage, cbSlippage }) {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(2);
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const selectSlippage = (percent, selected) => {
    console.log(percent, slippage);
    cbSlippage(percent);
    setSelected(selected);
  };

  useEffect(() => {
    switch (slippage) {
      case "0.1":
        selectSlippage(slippage, 0);
        break;
      case "0.5":
        selectSlippage(slippage, 1);
        break;
      case "1":
        selectSlippage(slippage, 2);
        break;
      default:
        break;
    }
  }, []);

  return (
    <>
      <div className="swap-header">
        <div className="flex flex-row justify-between mb-1">
          <h4>Swap</h4>
          <div className="flex flex-row">
            <button className="swap-history mr-3">
              <MdHistory style={{ width: "24px", height: "24px", color: "#fff" }} />
            </button>
            <button className="swap-setting" onClick={openModal}>
              <MdSettings style={{ width: "24px", height: "24px", color: "#fff" }} />
            </button>
          </div>
        </div>
        <p>Fast, Secure, KYC -Free</p>
      </div>
      <ModalWrap isOpen={isOpen} cbClose={closeModal}>
        <header className="modal-header">
          <div className="flex flex-row justify-between items-center">
            <h6>Setting</h6>
            <button onClick={closeModal}>
              <MdClose style={{ width: "16px", height: "16px", color: "#fff" }} />
            </button>
          </div>
        </header>
        <div className="modal-body">
          <div className="setting">
            <div className="slippage">
              <h4>Slippage Tolerlance</h4>
              <div className="flex flex-col sm:flex-row justify-between items-center">
                <button className={`percent ${selected == 0 ? "active" : ""}`} onClick={() => selectSlippage(0.1, 0)}>
                  0.1%
                </button>
                <button className={`percent ${selected == 1 ? "active" : ""}`} onClick={() => selectSlippage(0.5, 1)}>
                  0.5%
                </button>
                <button className={`percent ${selected == 2 ? "active" : ""}`} onClick={() => selectSlippage(1, 2)}>
                  1.0%
                </button>
                <button className={`percent ${selected == 3 ? "active" : ""}`} onClick={() => selectSlippage(1, 3)}>
                  5.0%
                </button>
                {/* <input
                  type="number"
                  className="percent custom"
                  placeholder="0.50%"
                  value={slippage}
                  onChange={e=>cbSlippage(e.target.value)}
                /> */}
              </div>
            </div>
          </div>
        </div>
      </ModalWrap>
    </>
  );
}
