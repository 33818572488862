import { getFormatEther } from "../../../web3/getEthers";

export default function SharePrice({ reserve, token0, token1 }) {
  let outToken0 = 0;
  let outToken1 = 0;
  // console.log(reserve, token0, token1);
  if (reserve.reserve0 !== undefined || reserve.reserve1 !== undefined) {
    outToken0 = getFormatEther(reserve.reserve0) / getFormatEther(reserve.reserve1);
    outToken1 = getFormatEther(reserve.reserve1) / getFormatEther(reserve.reserve0);
  }
  // console.log(reserve, token0, token1)
  return (
    <div className="share-price">
      {reserve.reserve0 !== undefined || reserve.reserve0 !== undefined ? (
        <>
          <p>{`${outToken1} ${token1.symbol} per ${token0.symbol}`}</p>
          <p>{`${outToken0} ${token0.symbol} per ${token1.symbol}`}</p>
        </>
      ) : (
        <p>No share price</p>
      )}
    </div>
  );
}
