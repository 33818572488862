import { createContext, useEffect, useState } from "react";
import { ethers } from "ethers";
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";

export const web3ModalContext = createContext();

export default function Web3ModalProvider({ children }) {
  const INITIAL_STATE = {
    fetching: false,
    account: "",
    provider: null,
    library: null,
    signer: null,
    connected: false,
    network: 1,
    chainId: 1,
    contract: null,
  };
  const [web3Modal, setWeb3Modal] = useState(null);
  const [web3Data, setWeb3Data] = useState({ ...INITIAL_STATE });

  const providerOptions = {
    walletconnect: {
      package: WalletConnectProvider,
      options: {
        network: "binance",
        rpc: process.env.REACT_APP_RPC_URL,
        chainId: web3Data.chainId,
      },
    },
    coinbasewallet: {
      package: CoinbaseWalletSDK, // Required
      options: {
        appName: "AETERNA Dapp", // Required
        infuraId: "", // Required
        rpc: process.env.REACT_APP_RPC_URL, // Optional if `infuraId` is provided; otherwise it's required
        chainId: web3Data.chainId, // Optional. It defaults to 1 if not provided
        darkMode: true // Optional. Use dark theme, defaults to false
      }
    },
    "custom-binancechainwallet": {
      display: {
        logo: "https://lh3.googleusercontent.com/rs95LiHzLXNbJdlPYwQaeDaR_-2P9vMLBPwaKWaQ3h9jNU7TOYhEz72y95VidH_hUBqGXeia-X8fLtpE8Zfnvkwa=w128-h128-e365-rj-sc0x00ffffff",
        name: "Binance Chain Wallet",
        darkMode: true,
        description: "Connect to your Binance Chain Wallet"
      },
      package: true,
      connector: async () => {
        let provider = null;
        if (typeof window.BinanceChain !== 'undefined') {
          provider = window.BinanceChain;
          try {
            const account = await provider.request({ method: 'eth_requestAccounts' })
            console.log(account[0]);
          } catch (error) {
            throw new Error("User Rejected");
          }
        } else {
          throw new Error("No Binance Chain Wallet found");
        }
        return provider;
      }
    },
  };

  const connectWallet = async () => {
    const web3Modal = new Web3Modal({
      network: "Binance",
      cacheProvider: true, // very important
      providerOptions,
    });
    setWeb3Modal(web3Modal);

    const provider = await web3Modal.connect();
    const library = new ethers.providers.Web3Provider(provider);
    const accounts = await library.listAccounts();
    const network = await library.getNetwork();

    const signer = library.getSigner();

    const chainId = await signer.getChainId();
    const binanceTestChainId = "0x61";
    if (chainId === binanceTestChainId) {
      console.log("Bravo!, you are on the correct network");
    } else {
      try {
        await provider.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: "0x61" }],
        });
        console.log("You have succefully switched to Binance Test network");
      } catch (switchError) {
        // This error code indicates that the chain has not been added to MetaMask.
        if (switchError.code === 4902) {
          try {
            await provider.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainId: "0x61",
                  chainName: "Binance Smart Chain Testnet",
                  rpcUrls: [process.env.REACT_APP_RPC_URL],
                  blockExplorerUrls: [process.env.REACT_APP_BSCSCAN_EXPLORER],
                  nativeCurrency: {
                    symbol: "tBNB",
                    decimals: 18,
                  },
                },
              ],
            });
          } catch (addError) {
            console.log(addError);
          }
        }

        return;
      }
    }

    let account = "";
    if (accounts) {
      account = accounts[0];
      setWeb3Data({ ...INITIAL_STATE, account });
    }
    setWeb3Data({ ...web3Data, provider, library, signer, account, connected: true, network, chainId });

    return { provider, library, signer, account, network, chainId };
  };

  const disconnectWallet = async () => {
    await web3Modal.clearCachedProvider();
    setWeb3Data({ ...INITIAL_STATE });
  };

  /** ethers.js utility functions */
  const getAccount = async () => (await web3Data.library?.listAccounts())[0];
  const getChainId = async () => await web3Data.signer.getChainId();
  const getTransactionCount = async () => await web3Data.signer.getTransactionCount();
  const getContract = (contractAddress, abi) => new ethers.Contract(contractAddress, abi, web3Data.signer);
  const getBalance = async () => await web3Data.library?.getBalance(await getAccount());
  const getBlockTimestamp = async () => (await web3Data.library.getBlock("latest")).timestamp;
  const getGasPrice = async () => await web3Data.library.getGasPrice();
  const getFormatEther = (balance) => ethers.utils.formatEther(balance);
  const getFormatUnits = (balance, decimal) => ethers.utils.parseUnits(balance, decimal || "ether");
  const getHashMEssage = (message) => ethers.utils.hashMessage(message);
  const getBignumberFrom = (number) => ethers.BigNumber.from(number);

  useEffect(() => {
    if (web3Modal?.cachedProvider) {
      connectWallet();
    }
  }, []);

  useEffect(() => {
    if (web3Data?.provider?.on) {
      const handleAccountsChanged = (accounts) => {
        console.log(accounts);
        setWeb3Data({ ...INITIAL_STATE, account: accounts });
      };

      const handleChainChanged = (chainId) => {
        console.log(chainId);
        setWeb3Data({ ...INITIAL_STATE, chainId });
      };

      const handleDisconnect = () => {
        console.log("disconnect");
        disconnectWallet();
      };

      web3Data?.provider.on("accountsChanged", handleAccountsChanged);
      web3Data?.provider.on("chainChanged", handleChainChanged);
      web3Data?.provider.on("disconnect", handleDisconnect);

      return () => {
        if (web3Data?.provider.removeListener) {
          web3Data?.provider.removeListener("accountsChanged", handleAccountsChanged);
          web3Data?.provider.removeListener("chainChanged", handleChainChanged);
          web3Data?.provider.removeListener("disconnect", handleDisconnect);
        }
      };
    }
  }, [web3Data?.provider]);

  return (
    <web3ModalContext.Provider
      value={{
        INITIAL_STATE,
        web3Modal,
        web3Data,
        setWeb3Data,
        connectWallet,
        disconnectWallet,
        getChainId,
        getTransactionCount,
        getContract,
        getBalance,
        getBlockTimestamp,
        getGasPrice,
        getFormatEther,
        getFormatUnits,
        getHashMEssage,
        getBignumberFrom,
      }}
    >
      {children}
    </web3ModalContext.Provider>
  );
}
