import { FaRegCalendarAlt } from "react-icons/fa";
import { MdRefresh } from "react-icons/md";
import Ticket from "./Ticket";

export default function PurchasedTicket(props) {
  return (
    <div className="purchased-ticket">
      <div className="flex justify-between items-center mb-6">
        <div className="title">
          <FaRegCalendarAlt style={{ fontSize: "24px", color: "#11bc56", marginRight: "8px" }} />
          <h5>Your lucky numbers</h5>
        </div>

        <button
          className="reset-button"
          onClick={() => {
            props.handleReset ? props.handleReset(props.item?.id) : "";
          }}
        >
          <MdRefresh style={{ fontSize: "23px", color: "#fff", marginRight: "8px" }} />
          RESET
        </button>
      </div>

      <Ticket pickedNumber={props.item?.pick} />
    </div>
  );
}
