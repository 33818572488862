import Stack from "@mui/material/Stack";
import { Link } from "react-router-dom";
import { Toaster } from "react-hot-toast";

export default function NavBar() {
  return (
    <Stack direction="row" justifyContent="space-evenly" alignItems="center" spacing={2} className="main-theme-color">
      <Link to="/escrow/dashboard" className="text-decoration-none text-white">
        <div style={{ fontSize: "25px" }}>History</div>
      </Link>
      <Link to="/escrow/product" className="text-decoration-none text-white">
        <div style={{ fontSize: "25px" }}>Product</div>
      </Link>
      <Link to="/escrow/service" className="text-decoration-none text-white">
        <div style={{ fontSize: "25px" }}>Service</div>
      </Link>
      <Link to="/escrow/cryptotrading" className="text-decoration-none text-white">
        <div style={{ fontSize: "25px" }}>CryptoTrading</div>
      </Link>
      <Link to="/escrow/postbulletin" className="text-decoration-none text-white">
        <div style={{ fontSize: "25px" }}>Post Bulletin</div>
      </Link>
      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            style: {
              background: "white",
              paddingLeft: 40,
              paddingRight: 40,
              fontWeight: 500,
            },
          },
          error: {
            style: {
              background: "white",
              color: "black",
              paddingLeft: 40,
              paddingRight: 40,
              fontWeight: 500,
            },
          },
        }}
      />
    </Stack>
  );
}
