import { useNavigate } from "react-router-dom";
import CircleNumber from "./CircleNumber";

export default function Ticket(props) {
  const navigate = useNavigate();

  return (
    <div
      className="ticket"
      onClick={() => {
        props.redirectable && navigate("/lottery/current/ticket");
      }}
    >
      <CircleNumber green number={props.pickedNumber?.length > 0 && parseInt(props.pickedNumber[0])} />
      <CircleNumber green number={props.pickedNumber?.length > 0 && parseInt(props.pickedNumber[1])} />
      <CircleNumber green number={props.pickedNumber?.length > 0 && parseInt(props.pickedNumber[2])} />
      <CircleNumber green number={props.pickedNumber?.length > 0 && parseInt(props.pickedNumber[3])} />
      <CircleNumber green number={props.pickedNumber?.length > 0 && parseInt(props.pickedNumber[4])} />
      <CircleNumber green number={props.pickedNumber?.length > 0 && parseInt(props.pickedNumber[5])} />
    </div>
  );
}
