import CoinStatsCard from "../components/CoinStatsCard";
import ComingSoon from "../components/Dashboard/ComingSoon";
import ComingBuyTicket from "../components/Dashboard/ComingBuyTicket";
import Exchange from "../components/Exchange";
import Staking from "../assets/images/dashboard/staking.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { stats } from "../constant/coinstats";

export default function Dashboard() {
  return (
    <div className="container mx-auto">
      <div className="dashboard">
        <div className="coin-stats">
          <div className="grid xl:grid-cols-4 grid-flow-row">
            {stats?.map((item, i) => (
              <CoinStatsCard
                key={i}
                className={item.className}
                title={item.title}
                value={item.value}
                unit={item.unit}
              />
            ))}
          </div>
        </div>

        <div className="ads grid grid-cols-3 gap-8 grid-flow-row">
          <div className="announce-staking col-span-3 xl:col-span-2">
            <div className="announce">
              <div className="title">ANNOUNCEMENT</div>
              <div className="announce-slide overflow-hidden w-full">
                <ComingSoon>
                  <ComingBuyTicket />
                </ComingSoon>
              </div>
            </div>
            <div className="staking">
              <img src={Staking} alt="Staking" />
            </div>
          </div>

          <div className="fiat col-span-3 xl:col-span-1">
            <div className="title">FIAT</div>
            <div className="fiat-body">
              <Exchange />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
