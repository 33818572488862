// import { useEffect, useState } from 'react';
import { Link, useLocation } from "react-router-dom";

export default function SidebarList(props) {
  const { items, handleMobile } = props;
  const location = useLocation();

  const getActive = (link) => {
    if (location.pathname.split("/").length > 1 && link.split("/").length > 1)
      return location.pathname.split("/")[1] === link.split("/")[1] ? true : false;
    else return false;
  };

  return items.map((item, index) => (
    <li className={`sidebar-item ${getActive(item.link) ? "active" : ""}`} key={index}>
      <Link to={item.link} className="item" onClick={() => handleMobile(false)}>
        <img src={getActive(item.link) ? item.img : item.img1} alt={item.dsp} />
        <p>{item.text}</p>
      </Link>
    </li>
  ));
}
