import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { useParams } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import toast from "react-hot-toast";
import { ethers } from "ethers";
import nftContractAbi from "../assets/Abis/escrow-abi.json";
import tokenContractAbi from "../web3/abi/tokenAbi.json";
import { testnetTokens } from "../constant/tokens";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Layout from "../components/Escrow/Layout";
import NavBar from "../components/Escrow/NavBar";
import getContractsAddress from "../web3/contractsAddress";
import { web3ModalContext } from "../components/Web3ModalProvider";

export default function EscrowCryptoTrading() {
  const { web3Modal, web3Data, setWeb3Data, connectWallet, getContract } = useContext(web3ModalContext);
  const escrowAddress = getContractsAddress(web3Data.chainId).escrowAddress;

  const params = useParams();
  const tradeId = params?.tradeId;
  console.log(tradeId);

  const [visitorType, setVisitorType] = useState(0); // 0: observer, 1: seller, 2: buyer
  const [isTradeCompleted, setIsTradeCompleted] = useState(false);

  const [cryptoAddressValue, setCryptoAddressValue] = useState("");
  const [cryptoAmountValue, setCryptoAmountValue] = useState("");
  const [cryptoPriceValue, setCryptoPriceValue] = useState("");

  const connect = async () => {
    try {
      const { provider, library, signer, account, network, chainId } = await connectWallet();
      setWeb3Data({ ...web3Data, provider, library, signer, account, connected: true, network, chainId });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (web3Modal?.cachedProvider) {
      connect();
    }
  }, []);

  useEffect(() => {
    if (web3Data.connected) {
      if (web3Data.signer) {
        decideVisitorType().then(({ trade }) => {
          if (tradeId) {
            setCryptoAddressValue(trade.currency.toString());
            setCryptoAmountValue(trade.amount.toString());
            setCryptoPriceValue(trade.price.toString());
          }
        });
      }
    }
  }, [web3Data.connected]);

  const handleCryptoAddressChange = (event) => {
    setCryptoAddressValue(event.target.value);
  };

  const handleCryptoAmountChange = (event) => {
    setCryptoAmountValue(event.target.value);
  };

  const handleCryptoPriceChange = (event) => {
    setCryptoPriceValue(event.target.value);
  };

  const decideVisitorType = async () => {
    let type = 0;
    let trade = undefined;
    if (tradeId) {
      const contract = getContract(escrowAddress, nftContractAbi);
      trade = await contract.cryptoTrades(tradeId);
      if (trade.seller === web3Data.account) type = 1;
      else if (trade.buyer === web3Data.account) type = 2;
      else type = 0;
      if (trade.completed) setIsTradeCompleted(true);
    }
    setVisitorType(type);
    return { type, trade };
  };

  const validateInputs = () => {
    let isValid = true;
    if (!ethers.utils.isAddress(cryptoAddressValue)) {
      isValid = false;
      toast.error("The crypto address is not valid");
      return;
    }

    if (cryptoAmountValue.length <= 0) {
      isValid = false;
      toast.error("Please input the amount");
      return;
    }

    if (parseInt(cryptoPriceValue) <= 0) {
      isValid = false;
      toast.error("Please input valid number for the price");
      return;
    }

    return isValid;
  };

  const handleApprove = async () => {
    if (!web3Data.connected) {
      toast.error("Connect the wallet first");
      return;
    }

    const isValid = validateInputs();
    if (!isValid) return;

    const contract = getContract(cryptoAddressValue, tokenContractAbi);

    await contract.approve(escrowAddress, cryptoAmountValue)
      .then((tx) => {
        return tx.wait().then(
          async (receipt) => {
            console.log(receipt);
            // This is entered if the transaction receipt indicates success
            toast.success("Successfully approved!");
            return true;
          },
          (error) => {
            console.log(error);
            toast.error("Transaction Failed!");
          }
        );
      })
      .catch((error) => {
        console.error("Transaction Failed!");
        if (error.message.indexOf("signature") > 0) {
          toast.error("You canceled transaction!");
        } else {
          toast.error(getHumanReadableMsg(error));
        }
      });
  };

  const handleCreateNewCryptoTrade = async () => {
    if (!web3Data.connected) {
      toast.error("Connect the wallet first");
      return;
    }

    const isValid = validateInputs();
    if (!isValid) return;

    const contract = getContract(escrowAddress, nftContractAbi);
    await contract.createNewCryptoTrade(cryptoAddressValue, cryptoAmountValue, cryptoPriceValue)
      .then((tx) => {
        return tx.wait().then(
          async (receipt) => {
            console.log(receipt);
            // This is entered if the transaction receipt indicates success
            toast.success("Successfully submitted!");
            setVisitorType(1); // seller
            return true;
          },
          (error) => {
            console.log(error);
            toast.error("Transaction Failed!");
          }
        );
      })
      .catch((error) => {
        console.error("Transaction Failed!");
        if (error.message.indexOf("signature") > 0) {
          toast.error("You canceled transaction!");
        } else {
          toast.error(getHumanReadableMsg(error));
        }
      });
  };

  const handleBuyCrypto = async () => {
    if (!web3Data.connected) {
      toast.error("Connect the wallet first");
      return;
    }

    const contract = getContract(escrowAddress, nftContractAbi);
    await contract.buyCrypto(tradeId, { value: cryptoPriceValue })
      .then((tx) => {
        return tx.wait().then(
          async (receipt) => {
            console.log(receipt);
            // This is entered if the transaction receipt indicates success
            toast.success("Successfully submitted!");
            setVisitorType(2); // buyer
            return true;
          },
          (error) => {
            console.log(error);
            toast.error("Transaction Failed!");
          }
        );
      })
      .catch((error) => {
        console.error("Transaction Failed!");
        if (error.message.indexOf("signature") > 0) {
          toast.error("You canceled transaction!");
        } else {
          toast.error(getHumanReadableMsg(error));
        }
      });
  };

  const getHumanReadableMsg = (error) => {
    let errorMsg = "";
    if (error.message.indexOf("Check the token allowance") > 0) errorMsg = "Approve first";
    else if (error.message.indexOf("Not enough paid") > 0) errorMsg = "Not enough paid";
    else if (error.message.indexOf("Already completed") > 0) errorMsg = "Already completed";
    else errorMsg = "Unknown error";

    return errorMsg;
  };

  if (web3Modal) {
    web3Data.provider?.once("block", () => {
      const contract = getContract(escrowAddress, nftContractAbi);
      contract.on("CryptoSold", (newTradeId) => {
        console.log("CryptoSold");
        console.log(newTradeId);
        if (newTradeId.toNumber() === tradeId) {
          setIsTradeCompleted(true);
        }
      });
    });
  }

  return (
    <Layout>
      <NavBar></NavBar>
      <Stack alignItems="center"></Stack>
      <div className="product-wrapper text-white">
        <h2>P2P Crypto Trade</h2>
        <h4>P2P is transparent, reliable and fast</h4>
        <Stack className="product-input-wrapper">
          <FormControl fullWidth className="currency-type">
            <InputLabel id="token-select-label" className="text-gray">
              Select Token
            </InputLabel>
            <Select
              labelId="token-select-label"
              id="token-select"
              value={cryptoAddressValue}
              label="Select Token"
              onChange={handleCryptoAddressChange}
              className="text-white"
            >
              {testnetTokens.map((row, index) => (
                <MenuItem key={index} value={row.address}>
                  {row.symbol}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div className="input-area">
            <input
              type="number"
              min="0"
              placeholder="Enter Amount"
              disabled={tradeId}
              value={cryptoAmountValue}
              onChange={handleCryptoAmountChange}
            />
          </div>
          <div className="input-area">
            <input
              type="text"
              placeholder="Enter Price"
              disabled={tradeId}
              value={cryptoPriceValue}
              onChange={handleCryptoPriceChange}
            />
          </div>
          {tradeId && visitorType !== 1 ? (
            <Stack>
              <Button
                variant="contained"
                className="seller-product-submit-btn"
                onClick={() => handleBuyCrypto()}
                disabled={isTradeCompleted}
              >
                Buy
              </Button>
            </Stack>
          ) : (
            <Stack>
              <Button
                variant="contained"
                className="seller-product-submit-btn"
                onClick={() => handleApprove()}
                disabled={isTradeCompleted || visitorType === 1}
              >
                Approve
              </Button>
              <Button
                variant="contained"
                className="seller-product-submit-btn"
                onClick={() => handleCreateNewCryptoTrade()}
                disabled={isTradeCompleted || visitorType === 1}
              >
                Submit
              </Button>
            </Stack>
          )}
          <Stack direction="row" justifyContent="space-around" alignItems="center">
            <Stack direction="row" justifyContent="center" alignItems="center">
              <HighlightOffIcon
                style={{
                  color: isTradeCompleted ? "gray" : "white",
                  fontSize: "35px",
                }}
              />
              <h3
                style={{
                  color: isTradeCompleted ? "gray" : "white",
                }}
              >
                Not completed
              </h3>
            </Stack>
            <Stack direction="row" justifyContent="center" alignItems="center">
              <CheckCircleOutlineIcon
                style={{
                  color: !isTradeCompleted ? "gray" : "white",
                  fontSize: "35px",
                }}
              />
              <h3
                style={{
                  color: !isTradeCompleted ? "gray" : "white",
                }}
              >
                Completed
              </h3>
            </Stack>
          </Stack>
        </Stack>
      </div>
    </Layout>
  );
}
