import TableRow from "./TableRow";
import { rule } from "../../constant/rule";

export default function TablBody() {
  return (
    <div className="rule-body">
      {rule.map((row, index) => (
        <TableRow row={row} key={index} />
      ))}
    </div>
  );
}
