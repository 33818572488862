import { BSC_Mainnet, BSC_Testnet } from "../config";

const getContractsAddress = (networkId) => {
  switch (networkId) {
    case BSC_Mainnet:
      return {
        escrowAddress: "0x6099529735Aa9D4806eBD6A0FDCEE277B2D172a2",
        PancakeswapRouter: "0x3C21BD61743e6896B755321dBDdB26590f3D805b",
        PancakeswapFactory: "0x1Fac197385FA8cB120747E5DB3820c90C476a0AB",
        WBNBAddress: "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c",
        PoolAddress: "0x89b825A87a2005863f3DB023aDf259e515c1e2d3",
        LottryAddress: "0xF0a65A27688E65406E3fF9a44516c985E909F06d",
        tokenAddress: "0x1753c1a29ff90ac6c52f46c23c6fda94d34a70eb",
        presaleAddress: "0xb2fc127d4f40807D6b6c590bcAf537c8A8f2E1E9",
      };
    case BSC_Testnet:
      return {
         escrowAddress: "0x642A20894923A8A43D5C79E823B27d556514f0b4",
         PancakeswapRouter: "0x7A025a82cE710ab2C90A60457038A3acf1C6907A",
         PancakeswapFactory: "0x26348D195cC578acb6186da3b4fEd58bA62A2533",
         WBNBAddress: "0x0Bc3350aa7Edc12f805574369B1484d44dDbbed9",
         PoolAddress: "0x89b825A87a2005863f3DB023aDf259e515c1e2d3",
         LottryAddress: "0x079de4b0e3C15806239EfdB321Df83d4f5d9aFC8",
         tokenAddress: "0x6B14046D7fB0a7F612cd628cC4C14b17f033Fab1",
         presaleAddress: "0x0Bc3350aa7Edc12f805574369B1484d44dDbbed9",
//         escrowAddress: "0x6099529735Aa9D4806eBD6A0FDCEE277B2D172a2",
//         PancakeswapRouter: "0x3C21BD61743e6896B755321dBDdB26590f3D805b",
//         PancakeswapFactory: "0x1Fac197385FA8cB120747E5DB3820c90C476a0AB",
//         WBNBAddress: "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd",
//         PoolAddress: "0x89b825A87a2005863f3DB023aDf259e515c1e2d3",
//         LottryAddress: "0xF0a65A27688E65406E3fF9a44516c985E909F06d",
//         tokenAddress: "0x1753c1a29ff90ac6c52f46c23c6fda94d34a70eb",
//         presaleAddress: "0xb2fc127d4f40807D6b6c590bcAf537c8A8f2E1E9",
      };
    default:
      return "0xC9Ad2F68059dFeB39DBb00A867ebB1f9b782f353";
  }
};

export default getContractsAddress;
