import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import socketClient from "socket.io-client";
import { getAvatarId, convertIpToHex } from "../utils/utils";

const SERVER = process.env.REACT_APP_SERVER_ADDRESS;

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function EscrowChat() {
  const params = useParams();
  const chatId = params?.chatId;

  const [currentChatId, setCurrentChatId] = useState(chatId);
  const [messageText, setMessageText] = useState("");
  const [chatHistory, setChatHistory] = useState([{ chatId, participants: 1, participantIds: [], messageHistory: [] }]); // chatId, participants, participantIds, messageHistory
  let myChatHistory = [];

  const socket = socketClient(SERVER);

  const handleChannelSelect = (id) => {
    socket.emit("channel-join", id, (ack) => {
      console.log(ack);
    });
  };

  const handleSendMessage = () => {
    if (!messageText) return;
    socket.emit("send-message", {
      chatId: currentChatId,
      messageText,
      senderName: sessionStorage.getItem("myIP"),
      dateTime: Date.now(),
    });
    setMessageText("");
  };

  const handleMessageTextChange = (event) => {
    setMessageText(event.target.value);
  };

  const handleMessageTextKeyPress = (event) => {
    if (event.key === "Enter") handleSendMessage();
  };

  const handleRoomClick = (chatId) => {
    console.log(chatId);
    setCurrentChatId(chatId);
  };

  useEffect(() => {
    handleChannelSelect(chatId);

    socket.on("connect", () => {
      console.log(`I'm connected with the back-end`, socket.id);
      sessionStorage.setItem("myIP", socket.id);
    });

    socket.on("channel", (channel) => {
      console.log("channel", channel, chatId);
      if (chatId.length > 6 && chatId === channel.id) {
        const updatedList = [
          {
            chatId: chatId,
            participants: channel.participants,
            participantIds: [],
            messageHistory: [],
          },
        ];
        console.log(updatedList);
        myChatHistory = updatedList;
        setChatHistory(updatedList);
      } else {
        let channelExists = false;
        let updatedList = myChatHistory.map((currentChatChannel) => {
          if (currentChatChannel.chatId === channel.id) {
            channelExists = true;
            return {
              ...currentChatChannel,
              participants: channel.participants,
            }; //gets everything that was already in item, and updates "participants"
          }
          return currentChatChannel; // else return unmodified item
        });
        if (!channelExists && channel.id.slice(0, 6) === chatId) {
          updatedList.push({
            chatId: channel.id,
            participants: channel.participants,
            participantIds: [],
            messageHistory: [],
          });
        }
        console.log(updatedList);
        myChatHistory = updatedList;
        setChatHistory(updatedList);
      }
    });

    socket.on("message", (message) => {
      console.log("message", message, myChatHistory);
      for (let i = 0; i < myChatHistory.length; i++) {
        if (message.chatId === myChatHistory[i].chatId) {
          const { avatarId, newParticipantAdded } = getAvatarId(myChatHistory[i].participantIds, message.senderName);

          const timeNow = new Date(message.dateTime);
          const newMessage = {
            avatarId,
            name: message.senderName,
            message: message.messageText,
            dateTime: timeNow.toGMTString(),
          };

          const updatedList = myChatHistory.map((currentChatChannel) => {
            if (currentChatChannel.chatId === message.chatId) {
              let newParticipantIds = currentChatChannel.participantIds;
              if (newParticipantAdded) newParticipantIds.push(message.senderName);
              let newMessageLists = currentChatChannel.messageHistory;
              newMessageLists.push(newMessage);
              return {
                ...currentChatChannel,
                participantIds: newParticipantAdded ? newParticipantIds : currentChatChannel.participantIds,
                messageHistory: newMessageLists,
              }; //gets everything that was already in item, and updates "participants"
            }
            return currentChatChannel; // else return unmodified item
          });
          console.log("updatedList", updatedList);
          setChatHistory(updatedList);
          break;
        }
      }
    });
  }, []);

  // const moveScrollBottom = () => {
  //   var messageHistoryDiv = document.getElementById("messageHistory");
  //   messageHistoryDiv.scrollTop = messageHistoryDiv.scrollHeight;
  // };

  const currentChat = chatHistory.filter((history) => history.chatId === currentChatId)[0];
  console.log(currentChat);

  return (
    <Container maxWidth="lg">
      <Grid container columnSpacing={{ xs: 1 }}>
        <Grid item xs={3} md={3}>
          <Item
            style={{
              height: "100vh",
              backgroundColor: "#120f54",
            }}
          >
            {chatHistory.length ? (
              chatHistory.map((eachChat, index) => (
                <Stack
                  key={index}
                  direction="row"
                  alignItems="center"
                  style={{
                    backgroundColor: "darkcyan",
                    marginBottom: "5px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleRoomClick(eachChat.chatId)}
                >
                  <img src="/images/avatar-group.svg" alt="avatar" className="width-50" />
                  <Stack>
                    <h3 className="text-white margin-left-20">Room #{eachChat.chatId}</h3>
                    <div className="text-white text-left margin-left-20">participants: {eachChat.participants}</div>
                  </Stack>
                </Stack>
              ))
            ) : (
              <Stack direction="row" alignItems="center" style={{ backgroundColor: "darkcyan" }}>
                <img src="/images/avatar-group.svg" alt="avatar" className="width-50" />
                <Stack>
                  <h2 className="text-white margin-left-20">Room #{chatId}</h2>
                  <div className="text-white text-left margin-left-20">participants: 1</div>
                </Stack>
              </Stack>
            )}
          </Item>
        </Grid>
        <Grid item xs={9} md={9}>
          <Item
            style={{
              height: "100vh",
              backgroundColor: "#120f54",
              position: "relative",
            }}
          >
            <Stack id="messageHistory" style={{ overflowY: "auto", height: "88%", margin: "20px" }}>
              {currentChat.messageHistory.length ? (
                currentChat.messageHistory.map((eachMessage, index) => (
                  <Stack key={index}>
                    <Stack direction="row" alignItems="center">
                      <img src={`/images/avatar${eachMessage.avatarId}.svg`} alt="avatar" className="width-50" />
                      <h2 className="text-white margin-left-20">{eachMessage.name}</h2>
                      <div className="text-white margin-left-20">{eachMessage.dateTime}</div>
                    </Stack>
                    <p className="message-body text-white">{eachMessage.message}</p>
                  </Stack>
                ))
              ) : (
                <h2 className="text-white margin-left-20">Messages will appear here.</h2>
              )}
            </Stack>
            <Stack direction="row" justifyContent="center" alignItems="center" className="message-textfield">
              <input
                type="text"
                placeholder="Your messages here..."
                className="chat-room-id"
                value={messageText}
                onChange={handleMessageTextChange}
                onKeyPress={handleMessageTextKeyPress}
              />
              <Button
                variant="contained"
                className="goto-chat-btn"
                onClick={() => handleSendMessage()}
                disabled={!messageText}
              >
                Send
              </Button>
            </Stack>
          </Item>
        </Grid>
      </Grid>
    </Container>
  );
}
