import { useState, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import LinearCard from "../components/Card/LinearCard";

export default function Liquidity() {
  return (
    <div className="liquidity-panel">
      <LinearCard className="liquidity-panel-card">
        <Outlet />
      </LinearCard>
    </div>
  );
}
