import { Link } from "react-router-dom";
import ComingSoon from "../components/Dashboard/ComingSoon";
import ComingBuyTicket from "../components/Dashboard/ComingBuyTicket";
import Footer from "../components/Footer";
import PoolBall from "../assets/images/dashboard/pool.png";
import Round from "../assets/images/dashboard/round.png";
import LotteryIcon from "../assets/images/sidebar/ticket1.png";
import EscrowIcon from "../assets/images/sidebar/give-money1.png";
import SwapIcon from "../assets/images/sidebar/swap1.png";
import StakingIcon from "../assets/images/sidebar/stake1.png";
import MediumIcon from "../assets/images/home/medium.png";
import TelegramIcon from "../assets/images/home/telegram.png";
import DiscordIcon from "../assets/images/home/discord.png";
import TwitterIcon from "../assets/images/home/twitter.png";
import AeternaLogo from "../assets/images/common/logo.png";
import BoxLogo from "../assets/images/home/box-logo.png";
import CoinmarketcapLogo from "../assets/images/home/coinmarketcap.png";
import CoinGeckoLogo from "../assets/images/home/CoinGecko.png";
import DigitalLogo from "../assets/images/home/digital.png";
import MarketwatchLogo from "../assets/images/home/marketwatch.png";
import NASDAQLogo from "../assets/images/home/NASDAQ_Logo.png";
import BloombergLogo from "../assets/images/home/bloomberg.png";
import YahooFinanceLogo from "../assets/images/home/yahoo.png";
import YahooNewLogo from "../assets/images/home/yahoo-news-logo.png";
import TitleLogo from "../assets/images/home/bg_title_blue.png";
import Ticket from "../assets/images/home/ticket.png";
import HandLogo from "../assets/images/home/handle-logo.png";
import PaymentLogo from "../assets/images/home/payment.png";
import InvestmentLogo from "../assets/images/home/investment.png";
import HomeTitle from "../components/HomeTitle";
import NewsCarousel from "../components/NewsCarousel";

export default function Home() {
  return (
    <div className="home">
      <div className="home-container">
        <div className="home-top bg-cover xl:bg-cover bg-no-repeat mb-8 md:mb-44">
          <div className="container lg:container mx-auto px-2 flex flex-row">
            <div className="top-left my-6 md:my-44">
              <h1>AETERNA</h1>
              <h4>ALL-IN-ONE SAFE CRYPTO PLATFORM</h4>
              <p className="text-white text-2xl">Utilities</p>
              <div className="home-top-utilites-items grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                <Link to="/lottery">
                  <div className="home-top-utilites-item">
                    <img src={LotteryIcon} alt="Lottery" />
                    <h3>LOTTERY</h3>
                  </div>
                </Link>
                <Link to="/escrow">
                  <div className="home-top-utilites-item">
                    <img src={EscrowIcon} alt="Escrow" />
                    <h3>ESCROW</h3>
                  </div>
                </Link>
                <Link to="/exchange/swap">
                  <div className="home-top-utilites-item">
                    <img src={SwapIcon} alt="Bridge" />
                    <h3>SWAP</h3>
                  </div>
                </Link>
                <Link to="/staking" className="col-span-1 sm:col-start-2">
                  <div className="home-top-utilites-item">
                    <img src={StakingIcon} alt="Staking" />
                    <h3>STAKING</h3>
                  </div>
                </Link>
              </div>
            </div>
            <div className="top-right hidden xl:block">
              <div className="mt-8">
                <img src={BoxLogo} alt="Box Logo" />
              </div>
              <div className="contract-address flex flex-col items-center">
                <h5>OFFICIAL CONTRACT</h5>
                <div className="contract">
                  <img src={AeternaLogo} alt="Logo" />
                  <p>{process.env.REACT_APP_AETERNA_ADDRESS}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="home-utilities">
          <div className="container lg:container mx-auto px-2">
            <h5>FEATURED IN</h5>
            <div className="utilities-list flex flex-col md:flex-row items-center py-5">
              <Link to="#" className="m-3">
                <img src={CoinmarketcapLogo} alt="" />
              </Link>
              <Link to="#" className="m-3">
                <img src={CoinGeckoLogo} alt="" />
              </Link>
              <Link to="#" className="m-3">
                <img src={NASDAQLogo} alt="" />
              </Link>
              <Link to="#" className="m-3">
                <img src={MarketwatchLogo} alt="" />
              </Link>
              <Link to="#" className="m-3">
                <img src={YahooFinanceLogo} alt="" />
              </Link>
              <Link to="#" className="m-3">
                <img src={YahooNewLogo} alt="" />
              </Link>
              <Link to="#" className="m-3">
                <img src={DigitalLogo} alt="" />
              </Link>
              <Link to="#" className="m-3">
                <img src={BloombergLogo} alt="" />
              </Link>
            </div>
            <div className="utilities-title mt-4">
              <div className="subtitle mt-16 md:mt-0">
                <img src={TitleLogo} alt="TitleLogo" className="hidden md:block" />
                <h5>OUR UTILITIES</h5>
              </div>
            </div>
            <div className="utilities-content overflow-hidden px-0 sm:px-20">
              <div className="ticket flex flex-col md:flex-row justify-center items-center">
                <div className="ticket-description order-2 md:order-1">
                  <div className="m-0 md:mr-28">
                    <p className="caption">PLAY TO EARN</p>
                    <h5>AETERNA LOTTERY</h5>
                    <p className="text">
                      The hubs will also act as a place of entertainment, with three different types of lotteries !
                    </p>
                  </div>
                </div>
                <div className="coming-soon mt-4 order-1 md:order-2">
                  <div className="image-wrap">
                    <div style={{ position: "relative" }}>
                      <img className="m-auto" src={Round} alt="round" />
                      <img className="pool-ball" src={PoolBall} alt="Pool ball" />
                    </div>
                  </div>
                  <ComingBuyTicket />
                </div>
              </div>
              <div className="handle flex flex-col md:flex-row justify-center items-center">
                <img src={HandLogo} alt="HandLogo" className="mx-0 md:mr-16 lg:mx-32 mb-8 md:mb-0" />
                <div className="handle-description">
                  <p className="caption">SERVICE</p>
                  <h5>ESCROW SERVICE</h5>
                  <p className="text">
                    As safety is of utmost importance to us, we will also be providing escrow services to contractors
                    and contractees alike!
                  </p>
                </div>
              </div>
              <div className="payment flex flex-col md:flex-row justify-center items-center">
                <div className="payment-description order-2 md:order-1">
                  <p className="caption">QUICK PAYMENT</p>
                  <h5>FIAT PAYMENT</h5>
                  <p className="text">
                    Forget using exchanges requiring 20 steps to buy your crypto. Instead, just purchase with Fiat!
                  </p>
                </div>
                <img
                  src={PaymentLogo}
                  alt="PaymentLogo"
                  className="mx-0 md:ml-16 lg:mx-32 mb-8 md:mb-0 order-1 md:order-2"
                />
              </div>
              <div className="investment flex flex-col md:flex-row justify-center items-center">
                <img src={InvestmentLogo} alt="investment" className="mx-0 md:mr-16 lg:mx-32 mb-8 md:mb-0" />
                <div className="investment-description">
                  <p className="caption">STAKING OPTIONS</p>
                  <h5>STAKING</h5>
                  <p className="text">
                    Take advantage of BSC’s (Binance Smart Chain’s) most trusted and stable yield earning platform.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="home-news">
          <div className="home-news-container container lg:container mx-auto px-2">
            <HomeTitle title="NEWS" />
            <NewsCarousel />
            <div className="text-center text-sky">
              <Link to="/news-list">more news</Link>
            </div>

            <div className="stay-connected">
              <h3>Stay connected</h3>
              <p>Subscribe and stay updated!</p>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-3">
                <div className="social-connect col-span-1">
                  <div className="social-connect-icon">
                    <img src={MediumIcon} alt="Medium Icon" />
                    <h4>Medium</h4>
                  </div>
                  <p>
                    Official announcements, campaign details, whitelist opportunities, partnership announcements and
                    more
                  </p>
                </div>
                <div className="social-connect col-span-1">
                  <div className="social-connect-icon">
                    <img src={TwitterIcon} alt="Twitter Icon" />
                    <h4>Twitter</h4>
                  </div>
                  <p>Get the latest updates and announcements from the Impossible ecosystem</p>
                </div>
                <div className="social-connect col-span-1">
                  <div className="social-connect-icon">
                    <img src={TelegramIcon} alt="Telegram Icon" />
                    <h4>Telegram</h4>
                  </div>
                  <p>Join our larger group and get your questions answered by the community </p>
                </div>
                <div className="social-connect col-span-1">
                  <div className="social-connect-icon">
                    <img src={DiscordIcon} alt="Discord Icon" />
                    <h4>Discord</h4>
                  </div>
                  <p>Chat with our team, get insights and discuss the impossible with active community members</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
