import CircleLogo from "../../assets/images/lottery/circle_logo.png";
import GreenCircleLogo from "../../assets/images/lottery/green_circle_logo.png";

export default function CircleNumber(props) {
  return (
    <div className="circle-number flex justify-center items-center">
      {props.green ? <img src={GreenCircleLogo} alt="Circle Number" /> : <img src={CircleLogo} alt="Circle Number" />}
      <p>{props.number > 0 ? props.number : "00"}</p>
    </div>
  );
}
