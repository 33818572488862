import { useContext } from "react";
import Moment from "react-moment";
import { FaRegCalendarAlt } from "react-icons/fa";
import PurchasedTicket from "./PurchasedTicket";
import { PickNumberContext } from "../../pages/PickTicket";

export default function PickedTicket() {
  const { pickedNum, setPickedNum, ticketCount } = useContext(PickNumberContext);
  const pickedDate = new Date();

  const handleReset = (id) => {
    const pickedNumArray = pickedNum.filter((item) => item.id !== id);
    setPickedNum(pickedNumArray);
  };

  return (
    <div className="picked-ticket">
      <p>
        Draw N19. <Moment format="YYYY-MM-DD">{pickedDate}</Moment>
      </p>
      <h2>{ticketCount} Tickets</h2>

      <div className="flex justify-start items-center mb-5">
        <FaRegCalendarAlt style={{ fontSize: "25px", color: "#11bc56", marginRight: "14px" }} />
        <h3>Pick your lucky numbers</h3>
      </div>
      <div className="picked-ticket-list overflow-y-auto">
        {pickedNum.length <= 0 ? (
          <PurchasedTicket />
        ) : (
          pickedNum.map((item, i) => <PurchasedTicket key={i} item={item} handleReset={(id) => handleReset(id)} />)
        )}
      </div>
    </div>
  );
}
