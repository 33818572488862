import { ethers } from "ethers";

let provider = null;

if (window.ethereum) {
  provider = new ethers.providers.Web3Provider(window.ethereum, "any");
} else if (window.web3) {
  // provider = new ethers.providers.Web3Provider(window.ethereum, "any");
  console.log(window.web3);
  provider = new ethers.providers.Web3Provider(window.web3, "any");
} else {
  provider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_RPC_URL);
}

export const getAccounts = async () => {
  try {
    const accounts = await provider.send("eth_requestAccounts", []);
    return accounts;
  } catch (error) {
    console.log(error);
  }
};

export default provider;
export const signer = provider.getSigner();
export const getChainId = async () => await signer.getChainId();
export const getTransactionCount = async () => await signer.getTransactionCount();
export const getContract = (contractAddress, abi) => new ethers.Contract(contractAddress, abi, signer);
export const getBalance = async () => await provider.getBalance((await getAccounts())[0]);
export const getBlockTimestamp = async () => (await provider.getBlock("latest")).timestamp;
export const getGasPrice = async () => await provider.getGasPrice();
export const getFormatEther = (balance) => ethers.utils.formatEther(balance);
export const getFormatUnits = (balance, decimal) => ethers.utils.parseUnits(balance, decimal || "ether");
export const getHashMEssage = (message) => ethers.utils.hashMessage(message);
export const getBignumberFrom = (number) => ethers.BigNumber.from(number);
