import { useEffect, useState } from "react";
import axios from "axios";
import GoogleMap from "../components/Contact/GoogleMap";
import { data } from "autoprefixer";

function ContactUs() {
  const [name, setName] = useState();
  const [from, setFrom] = useState();
  const [message, setMessage] = useState();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [])

  const sendMail = async ({ from, name, message }) => {
    await axios
      .post(`${process.env.REACT_APP_SERVER_ADDRESS}/sendmail`, { from, name, message })
      .then(res => console.log(res.data))
      .catch(err => console.log(err));
  };

  return (
    <div className="contact-us py-16">
      <div className="container mx-auto grid grid-cols-1 xl:grid-cols-2 gap-8 px-4">
        <div className="">
          <h1 className="text-white text-5xl sm:text-6xl text-center font-bold leading-[54px] mb-14">Contact us</h1>
          <div className="bg-[#15152e] rounded-2xl px-10 py-3">
            <h3 className="text-white text-3xl text-center font-bold leading-[54px] mb-4.5">You can find us at</h3>
            <h6 className="text-[#656565] text-base leading-[30px]">STREET</h6>
            <p className="text-white text-xl leading-[30px] mb-6">No 7 Temasek Boulevard#12-07</p>
            <h6 className="text-[#656565] text-base leading-[30px]">CITY</h6>
            <p className="text-white text-xl leading-[30px] mb-6">Suntec Tower One</p>
            <h6 className="text-[#656565] text-base leading-[30px]">COUNTRY</h6>
            <p className="text-white text-xl leading-[30px] mb-6">Singapore, 038987</p>
          </div>
          <a href="mailto:aeterna.eco" target="_top" className="block w-full bg-[#824cf4] rounded-xl text-white text-xl text-center leading-[54px] py-1 my-8 px-8" >Send message</a>
          {/* <button onClick={() => window.open('mailto:contact@aeterna.eco?subject=subject&body=body')}></button> */}
          {/* <div className="pt-[54px]">
            <h3 className="text-3xl text-white text-center font-bold leading-[54px] my-2">Let's get in touch</h3>
            <input
              type="text"
              name="name"
              placeholder="Your Name"
              className="w-full h-10 border border-solid border-slate-400 my-2.5 px-5 py-2 text-xl leading-[54px]"
              onChange={(e) => setName(e.target.value)}
            />
            <input
              type="text"
              name="email"
              placeholder="Your Mail"
              className="w-full h-10 border border-solid border-slate-400 my-2.5 px-5 py-2 text-xl leading-[54px]"
              onChange={(e) => setFrom(e.target.value)}
            />
            <textarea
              name="message"
              placeholder="Message"
              cols="8"
              className="w-full h-40 border border-solid border-slate-400 my-2.5 px-5 py-2 text-xl leading-[40px]"
              onChange={(e) => setMessage(e.target.value)}
            />
            <button type="button" className="bg-[#824cf4] rounded-xl text-white text-xl text-center leading-[54px] py-1 px-8" onClick={() => sendMail({ from, name: name, message })}>Send Message</button>
          </div> */}
        </div>
        <div className="map mt-16">
          <GoogleMap />
        </div>
      </div>
    </div>
  )
}

export default ContactUs;
