import TicketCard from "./TicketCard";

export default function TableRow(props) {
  const { row } = props;

  return (
    <div className="rule-row">
      <p className={`match ${row.first}`}>{row.match}</p>
      <TicketCard logo={row.logo} />
      <p className={`prize ${row.first}`}>{row.prize}%</p>
    </div>
  );
}
