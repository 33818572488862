import HomeIcon from "../assets/images/sidebar/home.png";
import DashboardIcon from "../assets/images/sidebar/dashboard.png";
import LinkIcon from "../assets/images/sidebar/link.png";
import MoneyIcon from "../assets/images/sidebar/give-money.png";
import UserIcon from "../assets/images/sidebar/user.png";
import SwapIcon from "../assets/images/sidebar/swap.png";
import StakeIcon from "../assets/images/sidebar/stake.png";
import LogoutIcon from "../assets/images/sidebar/logout.png";
import TicketIcon from "../assets/images/sidebar/ticket.png";
import HomeIcon1 from "../assets/images/sidebar/home1.png";
import DashboardIcon1 from "../assets/images/sidebar/dashboard1.png";
import LinkIcon1 from "../assets/images/sidebar/link1.png";
import MoneyIcon1 from "../assets/images/sidebar/give-money1.png";
import UserIcon1 from "../assets/images/sidebar/user1.png";
import SwapIcon1 from "../assets/images/sidebar/swap1.png";
import StakeIcon1 from "../assets/images/sidebar/stake1.png";
import Contactus from "../assets/images/sidebar/email.svg";
import Contactus1 from "../assets/images/sidebar/email1.svg";
import LogoutIcon1 from "../assets/images/sidebar/logout1.png";
import TicketIcon1 from "../assets/images/sidebar/ticket1.png";
import PresaleIcon from "../assets/images/sidebar/presale.png";

export const Menu = [
  { link: "/home", img1: HomeIcon1, img: HomeIcon, dsp: "Home", text: "Home" },
  {
    link: "/dashboard",
    img1: DashboardIcon1,
    img: DashboardIcon,
    dsp: "Home",
    text: "Dashboard",
  },
  // { link: "/account", img1: UserIcon1, img: UserIcon, dsp: "Home", text: "Account" },
  {
    link: "/exchange/swap",
    img1: SwapIcon1,
    img: SwapIcon,
    dsp: "Home",
    text: "Swap",
  },
  { link: "/escrow", img1: MoneyIcon1, img: MoneyIcon, dsp: "Home", text: "Escrow" },
  {
    link: "/lottery",
    img1: TicketIcon1,
    img: TicketIcon,
    dsp: "Home",
    text: "Lottery",
  },
  // { link: "/bridge", img1: LinkIcon1, img: LinkIcon, dsp: "Home", text: "Bridge" },
  {
    link: "/staking",
    img1: StakeIcon1,
    img: StakeIcon,
    dsp: "Home",
    text: "Staking",
  },
  // {
  //   link: "/presale",
  //   img1: PresaleIcon,
  //   img: PresaleIcon,
  //   dsp: "Home",
  //   text: "Presale",
  // },
  // { 'link': '/contactus', 'img1': Contactus1, 'img': Contactus, 'Contact Us': 'Home', 'text': 'Contact Us' },
  // {
  //   link: "/logout",
  //   img1: LogoutIcon1,
  //   img: LogoutIcon,
  //   dsp: "Home",
  //   text: "Logout",
  // },
];
