import CircleNumber from "./CircleNumber";

export default function TicketCard(props) {
  return (
    <div className="ticket-card">
      <img src={props.logo} alt="Ticket image" />
      <div className="card-wrap">
        <CircleNumber />
        <CircleNumber />
        <CircleNumber />
        <CircleNumber />
        <CircleNumber />
        <CircleNumber green={true} />
      </div>
    </div>
  );
}
