import LotteryABI from "../web3/abi/LotteryABI.json";
import tokenAbi from "../web3/abi/tokenAbi.json";
import getContractsAddress from "./contractsAddress";
import { getContract } from "../web3/getEthers";

const lotteryAddress = getContractsAddress(parseInt(process.env.REACT_APP_CHAIN_ID)).LottryAddress;
const tokenAddress = getContractsAddress(parseInt(process.env.REACT_APP_CHAIN_ID)).tokenAddress;

export const getLotteryContract = () => {
  const lottery = getContract(lotteryAddress, LotteryABI);
  return lottery;
};

export const getTokenContract = () => {
  const token = getContract(tokenAddress, tokenAbi);
  return token;
};
