import { useEffect, useState } from "react";

const TimerComponent = ({ requestLastTime }) => {
  const calculateTimeLeft = () => {
    let timeLeft = {};
    let difference = +new Date(requestLastTime) - +new Date().getTime();

    if (difference > 0) {
      timeLeft = {
        d: Math.floor(difference / (1000 * 60 * 60 * 24)) || 0,
        h: Math.floor((difference / (1000 * 60 * 60)) % 24) || 0,
        m: Math.floor((difference / 1000 / 60) % 60) || 0,
        s: Math.floor((difference / 1000) % 60) || 0,
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearTimeout(timer);
  });

  return (
    <>
      <div className="hours">
        <h5>{timeLeft["h"] ? timeLeft["h"] : "00"}</h5>
        <p>Hours</p>
      </div>
      <div className="minutes">
        <h5>{timeLeft["m"] ? timeLeft["m"] : "00"}</h5>
        <p>Minutes</p>
      </div>
      <div className="seconds">
        <h5>{timeLeft["s"] ? timeLeft["s"] : "00"}</h5>
        <p>Seconds</p>
      </div>
    </>
  );
};

export default TimerComponent;
