import axios from "axios";

const API_SERVER = process.env.REACT_APP_SERVER_ADDRESS;

const BULLETIN_LIST_URL = API_SERVER + "/getBulletins";
const CHAT_HISTORY_URL = API_SERVER + "/getChatHistory";
const POST_BULLETIN_URL = API_SERVER + "/postBulletin";

export function convertTimestampToDateTime(timestamp) {
  const d = new Date(timestamp * 1000);
  let s = d.toUTCString();
  s = s.substring(0, s.indexOf("GMT")) + "UTC";
  return s;
}

export function convertProductStateToStr(stateUint) {
  let stateStr = "";
  switch (stateUint) {
    case 0:
      stateStr = "AWAITING_DELIVERY";
      break;
    case 1:
      stateStr = "AWAITING_FUND_RELEASE";
      break;
    case 2:
      stateStr = "COMPLETE";
      break;
    default:
      break;
  }
  return stateStr;
}

export function convertCryptoStateToStr(stateUint) {
  let stateStr = "";
  switch (stateUint) {
    case true:
      stateStr = "Completed";
      break;
    case false:
      stateStr = "Pending";
      break;
    default:
      break;
  }
  return stateStr;
}

export function findCurrencyType(pairs, currencyAddress) {
  let currencyType = "";
  for (let i = 0; i < pairs.length; i++) {
    if (pairs[i].address === currencyAddress) {
      currencyType = pairs[i].symbol;
      break;
    }
  }
  return currencyType;
}

export function formatShortAddress(address) {
  const shortAddr = address.substr(0, 5) + "..." + address.substr(38);
  return shortAddr;
}

export function getHumanReadableMsg(error) {
  let errorMsg = "";
  if (error.message.indexOf("Not enough deposit") > 0) errorMsg = "Not enough deposit";
  else if (error.message.indexOf("Lock time is not passed yet") > 0) errorMsg = "Lock time is not passed yet.";
  else if (error.message.indexOf("Invalid state") > 0) errorMsg = "Invalid state";
  else if (error.message.indexOf("You are not the seller of this trade") > 0)
    errorMsg = "You are not the seller of this trade";
  else if (error.message.indexOf("You are not the buyer of this trade") > 0)
    errorMsg = "You are not the buyer of this trade";
  else if (error.message.indexOf("This trade is not set appeal") > 0) errorMsg = "This trade is not set appeal";
  else if (error.message.indexOf("This trade is set appeal") > 0) errorMsg = "This trade is set appeal";
  else if (error.message.indexOf("Funds already withdrawn") > 0) errorMsg = "Funds already withdrawn";
  else if (error.message.indexOf("Deadline expired") > 0) errorMsg = "Deadline expired";
  else if (error.message.indexOf("You should wait until the deadline is met") > 0)
    errorMsg = "You should wait until the deadline is met";
  else if (error.data.message.indexOf("insufficient funds") > 0) errorMsg = "Insufficient Funds in your wallet";

  return errorMsg;
}

export function genRandomChannelId() {
  let channelId = Math.random().toString(36).toUpperCase().slice(7);
  // always make its length as 6
  if (channelId.length < 6) channelId += "A";
  if (channelId.length > 6) channelId = channelId.slice(0, 6);
  return channelId;
}

export function genRandomString() {
  const channelId = Math.random().toString(36).toUpperCase().slice(11);
  return channelId;
}

export async function loadBulletinLists(bulletinId) {
  const response = await fetch(BULLETIN_LIST_URL + "?bulletin_id=" + bulletinId);
  const bulletinLists = response.json();
  return bulletinLists;
}

export async function loadChatHistory(room_no) {
  const response = await fetch(CHAT_HISTORY_URL + "?room_no=" + room_no);
  const chatHistory = response.json();
  return chatHistory;
}

export async function postNewBulletin(bulletin) {
  await axios
    .post(POST_BULLETIN_URL, { bulletin })
    .then((res) => {
      console.log(res.data);
    })
    .catch(function (err) {
      console.log(err, " error");
    });
  return;
}

export function getAvatarId(participantIds, sender) {
  let avatarId = 0;
  let newParticipantAdded = false;
  if (!participantIds.includes(sender)) {
    avatarId = participantIds.length;
    newParticipantAdded = true;
  } else {
    avatarId = participantIds.indexOf(sender);
  }
  return { avatarId, newParticipantAdded };
}

export function convertIpToHex(ip) {
  const hex =
    ((parseInt(ip.split(/[.]/)[0]) * 256) ^ 0) +
    ((parseInt(ip.split(/[.]/)[1]) * 256) ^ 1) +
    ((parseInt(ip.split(/[.]/)[2]) * 256) ^ 2) +
    ((parseInt(ip.split(/[.]/)[3]) * 256) ^ 3);
  return hex;
}
