import { useEffect, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";

export default function Exchange() {
  const [selectSwap, setSelectSwap] = useState(true);
  const location = useLocation();

  useEffect(() => {
    location.pathname === "/exchange/swap" ? setSelectSwap(true) : setSelectSwap(false);
  }, []);

  return (
    <div className="swap flex justify-center items-center mx-4">
      <div className="swap-container">
        <div className="swap-liquidity">
          <Link
            to="/exchange/swap"
            className={`swap-btn ${selectSwap ? "active" : ""}`}
            onClick={() => setSelectSwap(true)}
          >
            Swap
          </Link>
          <Link
            to="/exchange/liquidity/list"
            className={`liquidity-btn ${!selectSwap ? "active" : ""}`}
            onClick={() => setSelectSwap(false)}
          >
            Liquidity
          </Link>
        </div>
        <div className="swap-liquidity-content px-0 sm:p-8">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
