import { useState } from "react";
import { Link } from "react-router-dom";

export default function ComingBuyTicket() {
  const [ticketCount, setTicketCount] = useState(0);

  const handleTicketCount = (count) => {
    setTicketCount(count);
  };

  return (
    <div className="coming-buy-ticket">
      <h3>Grab a ticket</h3>
      <div className="current-pool">
        <p>Current price pool</p>
        <h5>21,746 AETERNAS</h5>
      </div>
      <p className="count">
        21,746 <span>AETERNAS</span>
      </p>
      <div className="dotteed"></div>
      <h1>COMING SOON</h1>
      <div className="ticket-input">
        <p>Number of tickets</p>
        <input type="number" min={0} max={999999} placeholder={0} onChange={(e) => handleTicketCount(e.target.value)} />
      </div>
      <div className="price2ticket flex flex-row justify-between mb-1">
        <p className="mb-1">Price per ticket</p>
        <p className="mb-1">0.36 AETERNA</p>
      </div>
      <div className="total flex flex-row justify-between">
        <p>Total</p>
        <p>3.60AETERNA</p>
      </div>
      <Link to="/lottery/pick/ticket" className="buy-ticket" state={{ ticketsCount: ticketCount }}>
        BUY TICKETS
      </Link>
    </div>
  );
}
