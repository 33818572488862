import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { FaTelegramPlane, FaTwitter, FaDiscord } from "react-icons/fa";
import certickLogo from "../assets/images/header/certik.png";
import solidLogo from "../assets/images/header/solid-proof.png";
import AeternaLogo from "../assets/images/dashboard/aeterna2.png";
import PresaleLogo from "../assets/images/presale/presale-logo.png";
import { web3ModalContext } from "../components/Web3ModalProvider";
import getContractsAddress from "../web3/contractsAddress";
import PresaleAbi from "../web3/abi/PresaleAbi.json";
import { DISCORD_URL, TELEGRAM_URL, TWITTER_URL } from "../config";

export default function Presale() {
  const { INITIAL_STATE, web3Modal, web3Data, setWeb3Data, connectWallet, disconnectWallet, getContract, getFormatEther, getBignumberFrom } = useContext(web3ModalContext);
  const presaleAddress = getContractsAddress(web3Data.chainId).presaleAddress;

  // const [account, setAccount] = useState(null);
  const [presaleContract, setPresaleContract] = useState(null);
  const [saleStatus, setSaleStatus] = useState(0);
  const [totalBnbReceived, setTotalBnbReceived] = useState(0);
  const [hardCap, setHardCap] = useState(0);
  const [progPercent, setProgPercent] = useState(0);
  const [amount, setAmount] = useState("");

  const connect = async () => {
    try {
      const { provider, library, signer, account, network, chainId } = await connectWallet();
      setWeb3Data({ ...web3Data, connected: true, provider, library, signer, account, network, chainId });
    } catch (err) {
      console.log(err);
    }
  };

  const disconnect = async () => {
    try {
      await disconnectWallet();
      setWeb3Data({...INITIAL_STATE});
    } catch (err) {
      console.log(err);
    }
  };

  const presale = async () => {
    try {
      if (amount <= 0) return;

      if (saleStatus === 0) {
        console.log("Sale is not active.");
        return;
      } else if (saleStatus === 2) {
        console.log("Sale is completed.");
        return;
      } else if (saleStatus === 1) {
        const result = await presaleContract.buy(web3Data.account, {
          value: getBignumberFrom(amount)
        });
        notify({
          text: `Buy ${amount} BNB `,
          link: `${process.env.REACT_APP_BSCSCAN_EXPLORER}/tx/${result.hash}`,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const initPresaleInfo = async () => {
    try {
      const contract = getContract(presaleAddress, PresaleAbi);
      setPresaleContract(contract);
      // console.log("0", contract);
      const saleStatus = await contract.saleStatus();
      // console.log("1", contract);
      const hardCap = await contract.hardCap();
      // console.log("2", contract);
      const totalBnbReceived = await contract.totalBnbReceived();
      // console.log("3", contract);
      const percent = parseInt(totalBnbReceived / hardCap);
      setProgPercent(percent);
      setHardCap(getFormatEther(hardCap));
      setSaleStatus(saleStatus);
      setTotalBnbReceived(getFormatEther(totalBnbReceived));
      // console.log(getFormatEther(hardCap), getFormatEther(totalBnbReceived));
    } catch (error) {
      console.log(error);
    }
  };

  const handleAmount = (value) => {
    console.log(value);
    if (parseFloat(value) < 0) setAmount("");
    else if (parseFloat(value) > 10) setAmount(10);
    setAmount(parseFloat(value));
  };

  useEffect(() => {
    if (web3Modal?.cachedProvider) {
      connect();
    }
  }, []);

  useEffect(() => {
    if (web3Data.connected) {
      if (web3Data.signer) initPresaleInfo();
    }
  }, [web3Data.connected]);

  return (
    <div className="presale flex flex-col w-full">
      <div className="presale-header grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 items-center justify-between md:justify-end h-auto">
        <div className="bg-[#7800a2] col-span-1 flex items-center justify-center mx-auto py-2 w-full h-20">
          <Link to="/home" className="flex items-center justify-center">
            <img src={AeternaLogo} alt="Aeterna Logo" className="w-16 h-16 mr-4" />
            <h1 className="text-white text-2xl font-bold text-center">AETERNA</h1>
          </Link>
        </div>
        <div className="bg-[#7800a2] col-span-1 mx-auto py-2 w-full text-center h-20">
          {web3Data.account ? (
            <button
              className="connect-button round-lg p-4 text-white text-xl text-center font-bold"
              onClick={() => disconnect()}
            >
              {web3Data.account.substring(0, 7) + " ... " + web3Data.account.substring(36)}
            </button>
          ) : (
            <button
              className="connect-button round-lg p-4 text-white text-xl text-center font-bold"
              onClick={() => connect()}
            >
              CONNECT WALLET
            </button>
          )}
        </div>
        <div className="audit pink-bold col-span-1 sm:col-span-2 xl:col-span-1 flex items-center justify-center py-2 w-full h-20">
          <h3 className="text-white text-xl font-bold mx-2">AUDITED BY:</h3>
          <img src={certickLogo} alt="certik logo" className="logo mx-2" />
          <img src={solidLogo} alt="Solid logo" className="logo mx-2" />
        </div>
      </div>

      <div className="presale-main container mx-auto block lg:grid lg:grid-cols-2 gap-4 flex items-center justify-center p-4">
        <div className="hidden lg:block lg:col-span-1 flex items-center justify-center mx-auto">
          <div className={`w-[80%]`}>
            <img src={AeternaLogo} alt="Aeterna Logo" />
          </div>
        </div>
        <div className="block lg:col-span-1 flex flex-col items-center justify-center mx-auto">
          <div className="presale-title flex justify-start items-center">
            <div className="mr-4">
              <img src={PresaleLogo} alt="Presale Logo" />
            </div>
            <div className="w-full">
              <h1 className="title text-7xl text-white mt-[20%]">PRESALE</h1>
            </div>
          </div>
          <div className="content text-center w-full p-4 sm:p-8">
            <div className="flex items-center mb-4">
              <label className="text-white text-2xl w-full">Buy input amount</label>
              <input
                className="bg-white text-right text-2xl text-black px-4 py-2 outline-none w-full h-auto"
                type="number"
                min={0}
                max="10"
                value={amount}
                placeholder="0.00 BNB"
                onChange={(e) => handleAmount(e.target.value)}
              />
            </div>
            <h1 className="text-white text-4xl mb-4">
              {totalBnbReceived}/{hardCap} BNB
            </h1>
            <div className="progress my-4">
              <div className={`value`} style={{ width: `${progPercent}%` }}></div>
            </div>
            <h3 className="text-white text-2xl my-4">{progPercent.toFixed(2)}% COMPLETE</h3>
            {web3Data.account ? (
              <button
                className="bg-[#7800a2] text-white font-bold text-3xl w-full sm:text-5xl p-8 disabled:opacity-[0.5] disabled:bg-[#707070]"
                disabled={!(saleStatus === 0 || saleStatus === 2)}
                onClick={() => presale()}
              >
                BUY PRESALE<br /> TOKEN
              </button>
            ) : (
              <button
                className="bg-[#7800a2] text-white font-bold text-3xl sm:text-5xl p-8"
                onClick={connect}
              >
                CONNECT WALLET TO PROCEED
              </button>
            )}
          </div>
        </div>
      </div>

      <div className="presale-footer flex flex-wrap lg:flex-nowrap items-center justify-between md:justify-end h-auto">
        <a
          href={TELEGRAM_URL}
          className="pink-bold grid-span-1 flex items-center justify-center mx-auto py-2 w-full h-20 text-white text-2xl font-bold"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="sky-light rounded-full p-2 m-2">
            <FaTelegramPlane style={{ fontSize: "32px" }} />
          </div>
          T.ME/AETERNAMAIN
        </a>
        <a
          href={TWITTER_URL}
          className="bg-[#7800a2] grid-span-1 flex items-center justify-center mx-auto py-2 w-full text-center text-white text-2xl font-bold h-20"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="sky-light rounded-full p-2 m-2">
            <FaTwitter style={{ fontSize: "32px" }} />
          </div>
          PROJECT_AETERNA
        </a>
        <a
          href={DISCORD_URL}
          className="pink-bold grid-span-1 flex items-center justify-center py-2 w-full h-20 text-white text-2xl font-bold"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="sky-dark rounded-lg p-2 m-2">
            <FaDiscord style={{ fontSize: "32px" }} />
          </div>
          PROJECTAETERNA
        </a>
      </div>
    </div>
  );
}
