import PoolBall from "../../assets/images/dashboard/pool.png";
import Round from "../../assets/images/dashboard/round.png";

export default function ComingSoon(props) {
  return (
    <div className="coming-soon">
      <h2>
        Join the <span>Mega Lottery!</span>
      </h2>
      <p>Use AETERNA to buy tickets and win big in the AETERNA's own lottery! </p>
      <div className="image-wrap">
        <div style={{ position: "relative" }}>
          <img className="m-auto" src={Round} alt="round" />
          <img className="pool-ball" src={PoolBall} alt="Pool ball" />
        </div>
      </div>
      {props.children}
    </div>
  );
}
