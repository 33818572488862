import { createContext, useEffect, useState, useContext } from "react";
import { Outlet } from "react-router-dom";
import getContractsAddress from "../web3/contractsAddress";
import LotteryABI from "../web3/abi/LotteryABI.json";
import tokenAbi from "../web3/abi/tokenAbi.json";
import { web3ModalContext } from "../components/Web3ModalProvider";

export const Web3Context = createContext();

export default function LotteryWrap() {
  const { web3Modal, web3Data, setWeb3Data, connectWallet, getContract } = useContext(web3ModalContext);
  const lotteryAddress = getContractsAddress(web3Data.chainId).LottryAddress;
  const tokenAddress = getContractsAddress(web3Data.chainId).tokenAddress;
  const [lotteryContract, setLotteryContract] = useState(null);
  const [tokenContract, setTokenContract] = useState(null);

  const connect = async () => {
    try {
      const { provider, library, signer, account, network, chainId } = await connectWallet();
      setWeb3Data({ ...web3Data, connected: true, provider, library, signer, account, network, chainId });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (web3Modal?.cachedProvider) {
      connect();
    }
  }, []);

  useEffect(() => {
    if (web3Data.connected) {
      if (web3Data.signer) initWeb3();
    }
  }, [web3Data.connected]);

  const initWeb3 = () => {
    const lottery = getContract(lotteryAddress, LotteryABI);
    const token = getContract(tokenAddress, tokenAbi);
    setLotteryContract(lottery);
    setTokenContract(token);
    console.log(lottery);
  };

  return (
    <Web3Context.Provider value={{ lotteryContract, tokenContract }}>
      <div className="lottery">
        <div className="lottery-container">
          <Outlet />
        </div>
      </div>
    </Web3Context.Provider>
  );
}
