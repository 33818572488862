import { useContext, useEffect, useState } from "react";
import { BiMinusCircle } from "react-icons/bi";
import { BiPlusCircle } from "react-icons/bi";
import LinearCard from "../Card/LinearCard";
import { PickNumberContext } from "../../pages/PickTicket";
import { web3ModalContext } from "../Web3ModalProvider";
import { Web3Context } from "../../pages/LotteryWrap";
import { notify } from "../../utils";

export default function CartSummary() {
  const { web3Modal, web3Data, setWeb3Data, connectWallet, getFormatEther, getBignumberFrom } = useContext(web3ModalContext);
  const { pickedNum, setPickedNum, setTicketCount } = useContext(PickNumberContext);
  const { lotteryContract, tokenContract } = useContext(Web3Context);
  const [ticketPrice, setTicketPrice] = useState(0);

  const connect = async () => {
    try {
      const { provider, library, signer, account, network, chainId } = await connectWallet();
      setWeb3Data({ ...web3Data, connected: true, provider, library, signer, account, network, chainId });
    } catch (err) {
      console.log(err);
    }
  };

  const getTicketPrice = async (count) => {
    try {
      if (count <= 0) return 0;
      console.log(count);
      const price = await lotteryContract.getLatestPrice();
      const balanceOfToken = await tokenContract.balanceOf(web3Data.account);
      const payableAmount = count * price * (balanceOfToken > 0 ? 1 : 2).toString();
      setTicketPrice(parseFloat(getFormatEther(payableAmount.toString())));
      return payableAmount;
    } catch (err) {
      console.log(err);
    }
  };

  const onBuyTicket = async () => {
    try {
      if (pickedNum.length <= 0) return;
      let pickedNumArray = [];
      let tupleArray = [];

      for (let i = 0; i < pickedNum.length; i++) {
        if (pickedNum[i] === undefined) continue;
        pickedNumArray.push(pickedNum[i].pick);
        tupleArray.push([...pickedNumArray]);
      }
      const currentLotteryId = await lotteryContract.currentLotteryId();

      const payableAmount = await getTicketPrice(pickedNum.length);
      const result = await lotteryContract.buyTickets(currentLotteryId, tupleArray, {
        value: getBignumberFrom(payableAmount.toString()),
      });

      setPickedNum([]);
      setTicketCount(0);
      setTicketPrice(0);
      notify({
        text: `Buy ${pickedNum.length} tickets for 5 AETERNA"`,
        link: `${process.env.REACT_APP_BSCSCAN_EXPLORER}/tx/${result.hash}`,
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (web3Modal?.cachedProvider) {
      connect();
    }
  }, []);

  useEffect(() => {
    getTicketPrice(pickedNum.length);
  }, [pickedNum]);

  return (
    <div className="cart-summary">
      <div className="panel-header">
        <h1 className="text-4xl">Cart Summary</h1>
        <p className="text-lg">Shop more tickets to get more chance</p>
      </div>

      <div className="panel-body">
        <div className="flex justify-between items-center mb-8">
          <h5 className="text-xl">Filled out Tickets</h5>
          <div className="flex justify-end items-center text-xl">
            {/* <button onClick={() => {
              ticketCount <= 0 ? setTicketCount(0) : setTicketCount(ticketCount - 1);
            }}>
              <BiMinusCircle
                style={{ fontSize: "26px", color: "#fff", marginRight: "24px" }}
              />
            </button> */}
            {pickedNum.length}
            {/* <button
              onClick={() => {
                setTicketCount(ticketCount + 1);
              }}
            >
              <BiPlusCircle
                style={{ fontSize: "26px", color: "#fff", marginLeft: "24px" }}
              />
            </button> */}
          </div>
        </div>

        <div className="flex justify-between items-center">
          <h3 className="text-xl">Ticket Price</h3>
          <h3 className="text-xl">${ticketPrice.toFixed(2).toLocaleString("en-US")}</h3>
        </div>
        <p className="text-[#2c9f82] mb-10">({pickedNum.length} tickets x 5 AETERNA)</p>
      </div>

      <div className="panel-footer">
        <div className="flex justify-between items-center w-full mb-10">
          <h1 className="text-2xl">TOTAL</h1>
          <h1 className="text-2xl">5 AETERNA</h1>
        </div>
        <LinearCard className="buy-ticket w-full">
          <button className="w-full" onClick={onBuyTicket} disabled={pickedNum.length <= 0}>
            Buy Ticket
          </button>
        </LinearCard>
      </div>
    </div>
  );
}
