import { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import { MdArrowBack } from "react-icons/md";
import { Oval } from "react-loader-spinner";
import { getLotteryContract } from "../web3/getLotteryContract";
import PastDrawsCarousel from "../components/Lottery/PastDrawsCarousel";
import DrawCard from "../components/Lottery/DrawCard";
import { web3ModalContext } from "../components/Web3ModalProvider";

export default function PastDraw() {
  const { web3Modal, web3Data, setWeb3Data, connectWallet } = useContext(web3ModalContext);
  const navigate = useNavigate();
  const lotteryContract = getLotteryContract();
  const [lotteryList, setLotteryList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const connect = async () => {
    try {
      const { provider, library, signer, account, network, chainId } = await connectWallet();
      setWeb3Data({ ...web3Data, connected: true, provider, library, signer, account, network, chainId });
    } catch (err) {
      console.log(err);
    }
  };

  const pastDraw = async (lotteryID) => {
    try {
      const lotteryInfo = await lotteryContract.viewLottery(lotteryID);
      const userInfo = await lotteryContract.viewUserInfoForLotteryId(web3Data.account, lotteryID, 0, 1000);
      const lottery = {
        id: lotteryID,
        startTime: lotteryInfo.startTime,
        endTime: lotteryInfo.endTime,
        amountCollected: lotteryInfo.amountCollected,
        ticketCount: lotteryInfo.firstTicketIdNextLottery - lotteryInfo.firstTicketId,
        finalNumber: lotteryInfo.finalNumber,
        status: lotteryInfo.status,
        tickets: userInfo[1],
        ticketsStats: userInfo[2],
      };
      // console.log(lotteryInfo, userInfo);
      return lottery;
    } catch (err) {
      console.log(err);
    }
  };

  const getPastDraws = async () => {
    try {
      const lotteries = [];
      setIsLoading(true);
      const lotteryId = await lotteryContract.currentLotteryId();
      for (let i = 0; i <= lotteryId; i++) {
        const lottery = await pastDraw(i);
        lotteries.push(lottery);
      }
      setLotteryList(lotteries);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (web3Modal?.cachedProvider) {
      connect();
    }
  }, []);

  useEffect(() => {
    if (web3Data.connected) {
      if (web3Data.signer) getPastDraws();
    }
  }, [web3Data.connected]);

  return (
    <div className="container mx-auto">
      <div className="past-draw">
        <button
          // to="/lottery"
          onClick={() => navigate(-1)}
          className="absolute flex items-center button text-white rounded-lg border border-white px-1 w-fit"
        >
          <MdArrowBack style={{ color: "#fff", marginRight: "4px" }} /> Back
        </button>

        <h3 className="text-center">PAST DRAWS</h3>

        {isLoading ? (
          <div className="flex items-center justify-center my-16">
            <Oval
              height={48}
              width={48}
              color="#ffffff"
              wrapperStyle={{}}
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="transparent"
              strokeWidth={4}
              strokeWidthSecondary={4}
            />
          </div>
        ) : lotteryList.length > 8 ? (
          <PastDrawsCarousel>
            {lotteryList.map((lottery, i) => (
              <DrawCard lottery={lottery} key={i} />
            ))}
          </PastDrawsCarousel>
        ) : 0 < lotteryList.length && lotteryList.length <= 8 ? (
          <div className="grid lg:grid-cols-4">
            {lotteryList.map((lottery, i) => (
              <DrawCard lottery={lottery} key={i} />
            ))}
          </div>
        ) : (
          <h3 className="text-center text-5xl my-8">Nothing Lottery</h3>
        )}
      </div>
    </div>
  );
}
