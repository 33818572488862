import { useEffect, useState, useContext } from "react";
import { Oval } from "react-loader-spinner";
import { BiQuestionMark } from "react-icons/bi";
import tokenAbi from "../../../web3/abi/tokenAbi.json";
import TokenLogo from "../../TokenLogo";
import { web3ModalContext } from "../../Web3ModalProvider";
import { testnetTokens } from "../../../constant/tokens";

export default function TokenCard({ tokenInfo, handleToken }) {
  const { web3Data, getContract, getBalance, getFormatEther } = useContext(web3ModalContext);
  const [token, setToken] = useState({});
  const [balance, setBalance] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadSuccess, setLoadSuccess] = useState(false);

  const getTokenInfo = async (address) => {
    const tokenContract = getContract(address, tokenAbi);
    const tokenBalance = await tokenContract.balanceOf(web3Data.account);
    const tokenSymbol = await tokenContract.symbol();
    const tokenName = await tokenContract.name();
    const tokenTotalSupply = await tokenContract.totalSupply();
    const tokenDecimals = await tokenContract.decimals();

    return {
      address: address,
      contract: tokenContract,
      name: tokenName,
      symbol: tokenSymbol,
      balance: tokenBalance,
      decimals: tokenDecimals,
      totalSupply: tokenTotalSupply,
    };
  };

  const getBNBBalance = async () => {
    const balance = await getBalance();
    return balance;
  };

  const getToken = async (address) => {
    try {
      let token = {};
      setLoading(true);
      if (address === testnetTokens[0].address) {
        // BNB
        const balance = await getBNBBalance();
        setBalance(parseFloat(getFormatEther(balance)).toFixed(2));
        const token = {
          name: testnetTokens[0].name,
          symbol: testnetTokens[0].symbol,
          balance: balance,
          decimals: testnetTokens[0].decimals,
          address: testnetTokens[0].address,
          totalSupply: 0,
          logoURI: testnetTokens[0].logoURI,
        };
        setToken(token);
        // console.log("tokencard", token);
      } else {
        token = await getTokenInfo(tokenInfo.address);
        setToken(token);
        getFormatEther(token.balance);
        setBalance(parseFloat(getFormatEther(token.balance)).toFixed(2));
        // console.log("tokencard", token);
      }
      setLoading(false);
      setLoadSuccess(true);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (web3Data.connected) {
      if (web3Data.signer) getToken(tokenInfo.address);
    }
  }, [web3Data.connected]);

  return (
    <div
      className="token-card"
      onClick={() => {
        if (loadSuccess === true) handleToken(token);
      }}
    >
      <div className="flex flex-row">
        <TokenLogo width="24px" height="24px">
          {tokenInfo.logoURI !== undefined ? (
            <img src={tokenInfo.logoURI} alt="Token Logo" />
          ) : (
            <BiQuestionMark style={{ fontSize: "18px" }} />
          )}
        </TokenLogo>
        <div>
          <h6>{tokenInfo.symbol}</h6>
          <p>{tokenInfo.name}</p>
        </div>
      </div>
      {loading === true ? (
        <div className="flex flex-row justify-center">
          <Oval
            height={16}
            width={16}
            color="#ffffff"
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="transparent"
            strokeWidth={4}
            strokeWidthSecondary={4}
          />
        </div>
      ) : (
        <h6>
          {balance} {tokenInfo.symbol}
        </h6>
      )}
    </div>
  );
}
