import { Map, GoogleApiWrapper, InfoWindow, Marker } from 'google-maps-react';
import { useState } from 'react';
import { Oval } from "react-loader-spinner";

function GoogleMap(props) {
  const [showingInfoWindow, setShowingInfoWindow] = useState(false);
  const [activeMarker, setActiveMarker] = useState({});
  const [selectedPlace, setSelectedPlace] = useState({});

  const onMarkerClick = (props, marker, e) => {
    setSelectedPlace(props);
    setActiveMarker(marker);
    setShowingInfoWindow(true);
  }

  const onClose = () => {
    if (showingInfoWindow) {
      setShowingInfoWindow(false);
      setActiveMarker(null);
    }
  };

  return (
    <div className="container mx-auto google-map text-center">
      <Map
        className="relative"
        google={props.google}
        language="en"
        zoom={10}
        style={{ position: "relative", width: "auto", height: "500px" }}
        initialCenter={
          {
            lat: 1.2955,
            lng: 103.8585
          }
        }
      >
        <Marker
          onClick={onMarkerClick}
          name={'Suntec Tower One, Singapore'}
        />
        <InfoWindow
          marker={activeMarker}
          visible={showingInfoWindow}
          onClose={onClose}
        >
          <div>
            <h4 className='text-[16px] text-[#333333] text-center font-normal leading-[20px]'>{selectedPlace.name}</h4>
          </div>
        </InfoWindow>
      </Map>
    </div>
  )
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_MAP_API_KEY
})(GoogleMap);
