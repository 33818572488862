import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Circles } from "react-loader-spinner";
import toast from "react-hot-toast";
import getContractsAddress from "../../web3/contractsAddress";
import { testnetTokens } from "../../constant/tokens";
import PancakeswapFactory from "../../web3/abi/PancakeswapFactory.json";
import Lp_abi from "../../web3/abi/Lp_abi.json";
import tokenAbi from "../../web3/abi/tokenAbi.json";
import LiquidityHeader from "./component/LiquidityHeader";
import LiquidityBody from "./component/LiquidityBody";
import LiquidityFooter from "./component/LiquidityFooter";
import PairLiquidity from "./component/PairLiquidity";
import ModalWrap from "../Modal";
import { MdSettings, MdClose } from "react-icons/md";
import { BsPlus } from "react-icons/bs";
import { web3ModalContext } from "../Web3ModalProvider";

export default function LiquidityList() {
  const { web3Modal, web3Data, setWeb3Data, connectWallet, getContract } = useContext(web3ModalContext);
  const [selected, setSelected] = useState(2);
  const [slippage, setSlippage] = useState(1);
  const [liquidities, setLiquidities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const getTokenInfo = async (address) => {
    try {
      const tokenContract = getContract(address, tokenAbi);
      const tokenBalance = await tokenContract.balanceOf(web3Data.account);
      const tokenTotalSupply = await tokenContract.totalSupply();
      const tokenSymbol = await tokenContract.symbol();
      const tokenName = await tokenContract.name();
      const tokenDecimals = await tokenContract.decimals();
      const tokenLogoURI = testnetTokens.filter((token) => token.symbol === tokenSymbol)[0].logoURI;

      return {
        address: address,
        // contract: tokenContract,
        name: tokenName,
        symbol: tokenSymbol,
        balance: tokenBalance,
        decimals: tokenDecimals,
        totalSupply: tokenTotalSupply,
        logoURI: tokenLogoURI,
      };
    } catch (err) {
      console.log("useEffect getTokenInfo is failed for " + address + ".\n", err);
    }
  };

  const getAllPairs = async () => {
    try {
      setLoading(true);
      const factoryAddress = getContractsAddress(web3Data.chainId);
      const factoryContract = getContract(factoryAddress.PancakeswapFactory, PancakeswapFactory);
      const pairLength = await factoryContract.allPairsLength();
      let temp = [];
      for (let i = 0; i < pairLength; i++) {
        const address = await factoryContract.allPairs(i);
        const pairContract = getContract(address, Lp_abi);
        const lpBalance = await pairContract.balanceOf(web3Data.account);
        const lpTotalSupply = await pairContract.totalSupply();
        const poolShare = parseFloat((lpBalance / lpTotalSupply) * 100).toFixed(2);
        const token0 = await getTokenInfo(await pairContract.token0());
        const token1 = await getTokenInfo(await pairContract.token1());
        const reserve = await pairContract.getReserves();

        const liquidity = {
          pairAddress: address,
          lpBalance,
          lpTotalSupply,
          reserve,
          token0,
          token1,
          poolShare,
        };
        // temp = liquidities.filter(item => item.pairAddress !== liquidity.pairAddress);
        temp.push(liquidity);
        console.log(pairLength, temp);
      }
      setLiquidities(temp);
      setLoading(false);
    } catch (err) {
      console.log("useEffect getAllPairs is failed.\n", err);
      setLoading(false);
    }
  };

  const selectSlippage = (percent, selected) => {
    // console.log(percent, slippage);
    setSlippage(percent);
    setSelected(selected);
  };

  const connect = async () => {
    try {
      const { provider, library, signer, account, network, chainId } = await connectWallet();
      setWeb3Data({ ...web3Data, provider, library, signer, account, connected: true, network, chainId });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (web3Modal?.cachedProvider) {
      connect();
    }
  }, []);

  useEffect(() => {
    if (web3Data.connected) {
      if (web3Data.signer) getAllPairs();
    }
  }, [web3Data.connected]);

  return (
    <>
      <LiquidityHeader className="">
        <div className="flex flex-row justify-between mb-1">
          <h4>Your Liquidity</h4>
          <button className="liquidity-setting" onClick={openModal}>
            <MdSettings style={{ width: "24px", height: "24px", color: "#fff" }} />
          </button>
        </div>
        <p>Remove liquidity to receive tokens back</p>
      </LiquidityHeader>
      <LiquidityBody>
        {web3Data.connected === false ? (
          <div className="warning-connect-wallet">
            <h6>Connect to a wallet to view your liquidity.</h6>
          </div>
        ) : loading === true ? (
          <div className="flex flex-row justify-center m-8">
            <Circles
              height="40"
              width="40"
              color="#ffffff"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        ) : liquidities.length <= 0 ? (
          <div className="warning-connect-wallet">
            <h6>No liquidity found.</h6>
            <h6>Don't see a pair you joined?</h6>
          </div>
        ) : (
          <div className="pair-token-list">
            {liquidities?.map((liquidity, index) => (
              <PairLiquidity
                key={index}
                liquidity={liquidity}
                slippage={slippage}
                selected={selected}
                removeLiquidity={() => setRemoveLiq(true)}
              />
            ))}
          </div>
        )}
      </LiquidityBody>
      <LiquidityFooter>
        {web3Data.connected === false ? (
          <div className="connect-wallet-btn">
            <button onClick={connect}>Connect Wallet</button>
          </div>
        ) : liquidities.length <= 0 ? (
          <div className="add-liquidity-btn">
            <Link
              to="/exchange/liquidity/add"
              state={{
                slippage: slippage,
                selected: selected,
              }}
              className="flex flex-row justify-center items-center"
            >
              <BsPlus style={{ color: "#fff", width: "24px", height: "24px" }} />
              Add Liquidity
            </Link>
          </div>
        ) : (
          <>
            <Link
              to="/exchange/liquidity/add"
              className="add-pair-token"
              state={{
                slippage: slippage,
                selected: selected,
              }}
            >
              Add
            </Link>
            <button className="import-pair-token">Import</button>
          </>
        )}
      </LiquidityFooter>
      <ModalWrap isOpen={isOpen} cbClose={closeModal}>
        <header className="modal-header">
          <div className="flex flex-row justify-between items-center">
            <h6>Setting</h6>
            <button onClick={closeModal}>
              <MdClose style={{ width: "16px", height: "16px", color: "#fff" }} />
            </button>
          </div>
        </header>
        <div className="modal-body">
          <div className="setting">
            <div className="slippage">
              <h4>Slippage Tolerlance</h4>
              <div className="flex flex-col sm:flex-row justify-between items-center">
                <button className={`percent ${selected == 0 ? "active" : ""}`} onClick={() => selectSlippage(0.1, 0)}>
                  0.1%
                </button>
                <button className={`percent ${selected == 1 ? "active" : ""}`} onClick={() => selectSlippage(0.5, 1)}>
                  0.5%
                </button>
                <button className={`percent ${selected == 2 ? "active" : ""}`} onClick={() => selectSlippage(1, 2)}>
                  1.0%
                </button>
                <button className={`percent ${selected == 3 ? "active" : ""}`} onClick={() => selectSlippage(1, 3)}>
                  5.0%
                </button>
                {/* <input
                  type="number"
                  className="percent custom"
                  placeholder={`${slippage}%`}
                  value={`${slippage}%`}
                  onChange={e=>setSlippage(e.target.value)}
                /> */}
              </div>
            </div>
          </div>
        </div>
      </ModalWrap>
    </>
  );
}
