import { createContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MdArrowBack } from "react-icons/md";

import PickedTicket from "../components/Lottery/PickedTicket";
import ChooseNumber from "../components/Lottery/ChooseNumber";
import CartSummary from "../components/Lottery/CartSummary";

export const PickNumberContext = createContext();

export default function PickTicket() {
  const location = useLocation();
  const navigate = useNavigate();
  const { ticketsCount } = location.state || 0;
  const [ticketCount, setTicketCount] = useState(ticketsCount);
  const [pickedNum, setPickedNum] = useState([]);

  return (
    <div className="pick-ticket">
      <PickNumberContext.Provider value={{ pickedNum, setPickedNum, ticketCount, setTicketCount }}>
        <div className="container mx-auto">
          <div className="w-min">
            <button
              // to="/lottery"
              onClick={() => navigate(-1)}
              className="flex items-center button text-white mb-3"
            >
              <MdArrowBack style={{ color: "#fff", marginRight: "4px" }} /> Back
            </button>
          </div>

          <div className="grid lg:grid-cols-2 xl:grid-cols-3 gap-6">
            <div className="lg:grid-span-1">
              <PickedTicket />
            </div>
            <div className="lg:grid-span-1">
              <ChooseNumber />
            </div>
            <div className="lg:col-start-1 lg:col-span-2 xl:col-start-3 xl:grid-span-1">
              <CartSummary />
            </div>
          </div>
        </div>
      </PickNumberContext.Provider>
    </div>
  );
}
