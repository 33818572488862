import { useState } from "react";
import { Link } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { DISCORD_URL, MEDIUM_URL, TELEGRAM_URL, TWITTER_URL } from "../../config";
import { HiOutlineMail } from "react-icons/hi";
import { MdLocationOn } from "react-icons/md";
import AeternaIcon from "../../assets/images/common/logo.png";
import MediumIcon from "../../assets/images/home/medium.png";
import TelegramIcon from "../../assets/images/home/telegram.png";
import TwitterIcon from "../../assets/images/home/twitter.png";
import DiscordIcon from "../../assets/images/home/discord.png";
import BinanceIcon from "../../assets/images/footer/binance.png";
import CopyIcon from "../../assets/images/footer/copy.png";

export default function Footer() {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    setCopied(true);
    const timer = setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  return (
    <div className="footer">
      <div className="container lg:container xl:container mx-auto px-4 pt-10 pb-6">
        <div className="grid lg:grid-cols-4 xl:grid-cols-5">
          <div className="social-contact hidden lg:grid lg:col-span-1 xl:col-span-2">
            <div className="footer-logo">
              <img src={AeternaIcon} alt="AETERNA LOGO" />
              <h5>Aeterna</h5>
            </div>
            <div className="social-logo">
              <a href={MEDIUM_URL} target="_blank" rel="noopener noreferrer">
                <img src={MediumIcon} alt="Medium" />
              </a>
              <a href={TWITTER_URL} target="_blank" rel="noopener noreferrer">
                <img src={TwitterIcon} alt="Twitter" />
              </a>
              <a href={TELEGRAM_URL} target="_blank" rel="noopener noreferrer">
                <img src={TelegramIcon} alt="Telegram" />
              </a>
              <a href={DISCORD_URL} target="_blank" rel="noopener noreferrer">
                <img src={DiscordIcon} alt="Discord" />
              </a>
            </div>
          </div>
          <div className="contact-us col-span-5 lg:col-span-2">
            <div className="grid grid-cols-1 sm:grid-cols-3">
              <div className="learn text-center lg:text-left">
                <h5 className="text-xl md:text-xs">Learn</h5>
                <Link to="">Whitepaper</Link>
                <Link to="">Medium</Link>
                <Link to="">FAQs</Link>
                <Link to="">Our community</Link>
              </div>
              <div className="buy text-center lg:text-left my-8 sm:my-0">
                <h5 className="text-xl md:text-xs">BUY AETERNA</h5>
                <Link to="">Aeterna Finance</Link>
                <Link to="">Digifinex</Link>
                <Link to="">Pancakeswap</Link>
              </div>
              <div className="explore text-center lg:text-left">
                <h5 className="text-xl md:text-xs">Exlore</h5>
                <Link to="">Dashboard</Link>
                <Link to="">Launchpad</Link>
              </div>
            </div>
          </div>
          <div className="token-info hidden lg:grid lg:col-span-1">
            <h5>Token Contracts</h5>
            <h4>Binance Smart Chain (BEP20)</h4>
            <div className="contract-address flex flex-center">
              <img className="aeterna-logo" src={AeternaIcon} alt="Aeterna Icon" />
              <p>0x17b3..........6AFCfED</p>
              <CopyToClipboard text={process.env.REACT_APP_AETERNA_ADDRESS} onCopy={handleCopy}>
                <button>
                  <img className="copy" src={CopyIcon} alt="Copy Icon" />
                </button>
              </CopyToClipboard>
              {copied ? <span style={{ color: "#ffffff" }}>Copied</span> : null}
            </div>
            <div className="binance">
              <img src={BinanceIcon} alt="Binance" />
            </div>
          </div>
        </div>
        <div className="flex flex-col xl:flex-row justify-center md:justify-between items-center mt-14">
          <p className="text-white text-center sm:text-left my-4">
            © 2022 Aeterna | All Rights Reserved | contact@aeterna.eco
          </p>
          <Link to="/contactus" className="flex justify-start items-center my-4">
            <p className="text-md text-white text-center underline leading-[30px] mx-2">No 7 Temasek Boulevard#12-07, Suntec Tower One, Singapore, 038987</p>
          </Link>
        </div>
      </div >
    </div >
  );
}
