import { useState } from "react";
import LinearCard from "../components/Card/LinearCard";
import SwapHeader from "../components/Swap/SwapHeader";
import SwapBody from "../components/Swap/SwapBody";

export default function Swap() {
  const [slippage, setSlippage] = useState(1);

  return (
    <div className="swap-panel">
      <LinearCard className="swap-panel-card">
        <SwapHeader slippage={slippage} cbSlippage={(value) => setSlippage(value)} />

        <SwapBody slippage={slippage} />
      </LinearCard>
    </div>
  );
}
