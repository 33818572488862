import TitleLogo from "../assets/images/home/bg_title_blue.png";

export default function HomeTitle(props) {
  const { title } = props;
  return (
    <div className="subtitle">
      <img src={TitleLogo} alt="TitleLogo" />
      <h5>{title}</h5>
    </div>
  );
}
