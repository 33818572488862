import { useLocation } from "react-router-dom";
import SidebarList from "./SidebarList";
import { Menu } from "../../constant/menu";
import logo from "../../assets/images/common/logo.png";

export default function Sidebar({ showMobile, handleMobile }) {
  const location = useLocation();

  return (
    <>
      {location.pathname === "/home" ? (
        <></>
      ) : (
        <div className={`sidebar z-50 mt-14 absolute md:relative md:mt-0 md:block ${showMobile ? "" : "hidden"}`}>
          <div className="sidebar-top">
            <img src={logo} alt="Aeterna Logo" />
            <h1 className="sidebar-title">AETERNA</h1>
          </div>

          <ul className="sidebar-body">
            <SidebarList items={Menu} handleMobile={(show) => handleMobile(show)} />
          </ul>
        </div>
      )}
    </>
  );
}
