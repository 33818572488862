import Modal from "react-modal";

export default function ModalWrap(props) {
  const { children, isOpen, cbClose, customStyle } = props;

  let initStyles = {
    content: {
      minWidth: "425px",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      padding: "0px",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      background: "#000000",
      border: "1px solid #707070",
      borderRadius: "16px",
      color: "#fff",
    },
  };
  const setStyle = { ...initStyles, customStyle };

  return (
    <Modal isOpen={isOpen} onRequestClose={cbClose} style={setStyle}>
      {children}
    </Modal>
  );
}
