import { useEffect, useState, useContext } from "react";
import { Oval } from "react-loader-spinner";
import toast from "react-hot-toast";
import { useWeb3React } from "@web3-react/core";
import getContractsAddress from "../../web3/contractsAddress";
import PancakeswapFactory from "../../web3/abi/PancakeswapFactory.json";
import PancakeswapRouter from "../../web3/abi/PancakeswapRouter.json";
import { injected } from "../../utils/connector";
import { BiQuestionMark } from "react-icons/bi";
import TokenLogo from "../TokenLogo";
import { web3ModalContext } from "../Web3ModalProvider";

import SwapIcon from "../../assets/images/dashboard/arrow.png";
import BNBIcon from "../../assets/images/dashboard/Binance-Coin-BNB-icon.png";
import AeternaIcon from "../../assets/images/dashboard/aeterna2.png";
import { testnetTokens } from "../../constant/tokens";
import tokenList from "../../web3/pancakeswap-top-15";
import Lp_abi from "../../web3/abi/Lp_abi.json";
import tokenAbi from "../../web3/abi/tokenAbi.json";
import { notify } from "../../utils";
import { SWAP_POSITION, BSC_Mainnet, BSC_Testnet } from "../../config";

export default function Exchange() {
  const { web3Modal, web3Data, setWeb3Data, connectWallet, getContract, getBalance, getFormatUnits, getFormatEther, getBignumberFrom, getBlockTimestamp } = useContext(web3ModalContext);
  const routerAddress = getContractsAddress(web3Data.chainId).PancakeswapRouter;
  const slippage = 1;

  const [currency0Address, setCurrency0Address] = useState(testnetTokens[0].address);
  const [currency1Address, setCurrency1Address] = useState(web3Data.chainId == BSC_Mainnet ? getContractsAddress(web3Data.chainId).tokenAddress : testnetTokens[2].address);
  const [currency1, setCurrency1] = useState(null);
  const [currency0, setCurrency0] = useState(null);
  const [currency0Amount, setCurrency0Amount] = useState(0);
  const [currency1Amount, setCurrency1Amount] = useState(0);
  const [swapLoading, setSwapLoading] = useState(false);
  const [currency0Loading, setCurrency0Loading] = useState(false);
  const [currency1Loading, setCurrency1Loading] = useState(false);
  const [approveToken, setApproveToken] = useState([]);
  const [direction, setDirection] = useState(SWAP_POSITION.FROM);

  const initTokens = () => {
    setCurrency0Address(testnetTokens[0].address);
    setCurrency1Address(web3Data.chainId == BSC_Mainnet ? getContractsAddress(web3Data.chainId).tokenAddress : testnetTokens[2].address);
    setCurrency1(null);
    setCurrency0(null);
    setCurrency0Amount(0);
    setCurrency1Amount(0);
  };

  const swapToken = () => {
    setCurrency1Address(currency0Address);
    setCurrency0Address(currency1Address);
    setCurrency0Amount(0);
    setCurrency1Amount(0);
  };

  const getBNBBalance = async () => {
    const balance = await getBalance();
    return balance;
  };

  const getTokenInfo = async (address) => {
    try {
      const tokenContract = getContract(address, tokenAbi);
      const tokenBalance = await tokenContract.balanceOf(web3Data.account);
      const tokenTotalSupply = await tokenContract.totalSupply();
      const tokenSymbol = await tokenContract.symbol();
      const tokenName = await tokenContract.name();
      const tokenDecimals = await tokenContract.decimals();
      const tokenAllowance = await tokenContract.allowance(web3Data.account, routerAddress);
      let tokenLogo = undefined;

      if (web3Data.chainId === BSC_Mainnet) {
        const token = tokenList.filter((token) => token.address === address);
        tokenLogo = token[0].logoURI;
      } else if (web3Data.chainId === BSC_Testnet) {
        const token = testnetTokens.filter((token) => token.address === address);
        tokenLogo = token[0].logoURI;
      }

      return {
        contract: tokenContract,
        address: address,
        name: tokenName,
        symbol: tokenSymbol,
        balance: tokenBalance,
        decimals: tokenDecimals,
        totalSupply: tokenTotalSupply,
        allowance: tokenAllowance,
        logoURI: tokenLogo,
      };
    } catch (err) {
      console.log("getTokenInfo is failed for " + address + ".\n", err);
    }
  };

  const getToken = async (address, pos) => {
    pos === SWAP_POSITION.FROM ? setCurrency0Loading(true) : setCurrency1Loading(true);
    try {
      if (address === testnetTokens[0].address) {
        // BNB
        const balance = await getBNBBalance();
        const currency = {
          name: testnetTokens[0].name,
          symbol: testnetTokens[0].symbol,
          balance: balance,
          decimals: testnetTokens[0].decimals,
          address: testnetTokens[0].address,
          totalSupply: undefined,
          logoURI: testnetTokens[0].logoURI,
        };
        pos === SWAP_POSITION.FROM ? setCurrency0(currency) : setCurrency1(currency);
      } else {
        // Token
        const currency = await getTokenInfo(address);
        pos === SWAP_POSITION.FROM ? setCurrency0(currency) : setCurrency1(currency);

        if (getBignumberFrom(currency.allowance).lt(getFormatUnits(currency.totalSupply.toString()))) {
          const token = approveToken.filter((token) => token.symbol !== currency.symbol);
          setApproveToken([...token, currency]);
        }
      }
    } catch (err) {
      console.log(`getToken by ${pos === SWAP_POSITION.FROM ? "FROM" : "TO"} is failed.\n`, err);
      pos === SWAP_POSITION.FROM ? setCurrency0Loading(false) : setCurrency1Loading(false);
    }
    pos === SWAP_POSITION.FROM ? setCurrency0Loading(false) : setCurrency1Loading(false);
  };

  const getTokens = async () => {
    try {
      if (currency0Address !== "") {
        await getToken(currency0Address, SWAP_POSITION.FROM);
      }
      if (currency1Address !== "") {
        await getToken(currency1Address, SWAP_POSITION.TO);
      }
    } catch (err) {
      console.log("getTokens is failed.\n", err);
    }
  };

  const swapETH4Aeterna = async (contract, path, amount0, amount1, deadline) => {
    let result = {};
    const timestamp = await getBlockTimestamp();

    if (direction === SWAP_POSITION.FROM) {
      const amount1Min = await handle0Amount(amount0); // token2ETH = positive
      result = await contract.swapExactETHForTokens(
        // complete
        getBignumberFrom(amount1Min)
          .mul(100 - slippage)
          .div(100),
        path,
        web3Data.account,
        timestamp + 1200,
        { value: getFormatUnits(parseFloat(amount0).toFixed(18).toString()) }
      );
    } else if (direction === SWAP_POSITION.TO) {
      // complete
      const amount0Max = await handle1Amount(amount1);
      result = await contract.swapETHForExactTokens(
        getBignumberFrom(amount1)
          .mul(100 - slippage)
          .div(100),
        path,
        web3Data.account,
        timestamp + 1200,
        { value: amount0Max.toString() }
      );
    }

    return result;
  };

  const swapAeterna4ETH = async (contract, path, amount0, amount1, deadline) => {
    let result = {};
    const timestamp = await getBlockTimestamp();

    if (direction === SWAP_POSITION.FROM) {
      const amount1Min = await handle0Amount(amount0);
      result = await contract.swapExactTokensForETH(
        // complete
        getFormatUnits(parseFloat(amount0).toFixed(18).toString()),
        getBignumberFrom(amount1Min)
          .mul(100 - slippage)
          .div(100),
        path,
        web3Data.account,
        timestamp + 1200
      );
    } else if (direction === SWAP_POSITION.TO) {
      // complete
      const amount0Max = await handle1Amount(amount1);
      result = await contract.swapTokensForExactETH(
        getFormatUnits(parseFloat(amount1).toFixed(18).toString()),
        getBignumberFrom(amount0Max)
          .mul(100 - slippage)
          .div(100),
        path,
        web3Data.account,
        timestamp + 1200
      );
    }
    return result;
  };

  const handleSwap = async () => {
    setSwapLoading(true);
    try {
      const routerContract = getContract(routerAddress, PancakeswapRouter);

      let result = {};

      if (currency0Address === testnetTokens[0].address) {
        const path = [getContractsAddress(web3Data.chainId).WBNBAddress, currency1Address];
        result = await swapETH4Aeterna(routerContract, path, currency0Amount, currency1Amount, 0);
      } else if (currency1Address === testnetTokens[0].address) {
        const path = [currency0Address, getContractsAddress(web3Data.chainId).WBNBAddress];
        result = await swapAeterna4ETH(routerContract, path, currency0Amount, currency1Amount, 0);
      }

      setCurrency0Amount(0);
      setCurrency1Amount(0);
      notify({
        text: `Swap ${currency0Amount} ${currency0.symbol} for ${currency1Amount} ${currency1.symbol}`,
        link: `${process.env.REACT_APP_BSCSCAN_EXPLORER}/tx/${result.hash}`,
      });

      const timer = setTimeout(() => {
        getTokens();
      }, 4000);

      setSwapLoading(false);
    } catch (err) {
      setSwapLoading(false);
      console.log("handleSwap is failed.\n", err);
    }
  };

  const handle0Amount = async (amount) => {
    try {
      let amount1Min = 0;
      const routerContract = getContract(routerAddress, PancakeswapRouter);

      if (amount !== undefined || amount !== "" || amount > 0) {
        setDirection(SWAP_POSITION.FROM);
        setCurrency0Amount(amount);

        const path =
          currency0Address === testnetTokens[0].address
            ? [getContractsAddress(web3Data.chainId).WBNBAddress, currency1Address]
            : currency1Address === testnetTokens[0].address
            ? [currency0Address, getContractsAddress(web3Data.chainId).WBNBAddress]
            : [currency0Address, currency1Address];
        amount1Min = (await routerContract.getAmountsOut(getFormatUnits(amount), path))[1];
        setCurrency1Amount(parseFloat(getFormatEther(amount1Min)).toFixed(6));

        return amount1Min;
      }
    } catch (err) {
      console.log("handle0Amount is failed.\n", err);
    }
  };

  const handle1Amount = async (amount) => {
    try {
      let amount0 = 0;
      const routerContract = getContract(routerAddress, PancakeswapRouter);

      if (amount !== undefined || amount !== "" || amount > 0) {
        setDirection(SWAP_POSITION.TO);
        setCurrency1Amount(amount);

        const path =
          currency0Address === testnetTokens[0].address
            ? [getContractsAddress(web3Data.chainId).WBNBAddress, currency1Address]
            : currency1Address === testnetTokens[0].address
            ? [currency0Address, getContractsAddress(web3Data.chainId).WBNBAddress]
            : [currency0Address, currency1Address];
        amount0 = (await routerContract.getAmountsIn(getFormatUnits(amount), path))[0];
        setCurrency0Amount(parseFloat(getFormatEther(amount0)).toFixed(6));

        return amount0;
      }
    } catch (err) {
      console.log("handle1Amount is failed.\n", err);
    }
  };

  const connect = async () => {
    try {
      const { provider, library, signer, account, network, chainId } = await connectWallet();
      setWeb3Data({ ...web3Data, provider, library, signer, account, connected: true, network, chainId });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (web3Modal?.cachedProvider) {
      connect();
    }
  }, []);

  useEffect(() => {
    if (web3Data.connected) {
      if (web3Data.signer) getTokens();
    } else {
      initTokens();
    }
  }, [web3Data.connected, currency0Address, currency1Address]);

  return (
    <>
      <div className="exchange">
        <div className="exchange-pay">
          <div className="pay-left">
            <h3>You pay</h3>
            <form>
              <input
                name="payValue"
                type="number"
                placeholder="0.00"
                min={0}
                value={currency0Amount}
                onChange={(e) => handle0Amount(e.target.value)}
              />
            </form>
            <h4>~$0.00</h4>
          </div>
          <div className="pay-right">
            <button className="pay-crypto">
              <TokenLogo width="24px" height="24px">
                {currency0?.logoURI === undefined ? (
                  <BiQuestionMark style={{ fontSize: "30px" }} />
                ) : (
                  <img src={currency0?.logoURI} alt={currency0?.symbol} />
                )}
              </TokenLogo>
              <p>{currency0?.symbol}</p>
            </button>
            <h4>
              Balance:{" "}
              {currency0 ? parseFloat(getFormatEther(currency0?.balance)).toFixed(2) : parseFloat(0).toFixed(2)}
            </h4>
          </div>
          <button className="exchange-button" onClick={() => swapToken()}>
            <img src={SwapIcon} alt="Swap icon" />
          </button>
        </div>
        <div className="exchange-get">
          <div className="get-left">
            <h3>You get</h3>
            <form>
              <input
                name="payValue"
                type="number"
                placeholder="0.00"
                min={0}
                value={currency1Amount}
                onChange={(e) => handle1Amount(e.target.value)}
              />
            </form>
            <h4>~$0.00</h4>
          </div>
          <div className="get-right">
            <button className="get-crypto">
              <TokenLogo width="24px" height="24px">
                {currency1?.logoURI === undefined ? (
                  <BiQuestionMark style={{ fontSize: "30px" }} />
                ) : (
                  <img src={currency1?.logoURI} alt={currency1?.symbol} />
                )}
              </TokenLogo>
              <p>{currency1?.symbol}</p>
            </button>
            <h4>
              Balance:{" "}
              {currency1 ? parseFloat(getFormatEther(currency1?.balance)).toFixed(2) : parseFloat(0).toFixed(2)}
            </h4>
          </div>
        </div>
      </div>
      {web3Data.connected ? (
        <button
          className="connect-wallet"
          disabled={swapLoading || currency0Amount <= 0 || currency1Amount <= 0}
          onClick={handleSwap}
        >
          {swapLoading && (
            <Oval
              height={16}
              width={16}
              color="#ffffff"
              wrapperStyle={{ marginRight: "8px" }}
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="transparent"
              strokeWidth={4}
              strokeWidthSecondary={4}
            />
          )}
          Confirm swap
        </button>
      ) : (
        <button className="connect-wallet" onClick={connect}>
          Connect your wallet
        </button>
      )}
      <div className="share-earn">Share & Earn</div>
      <div className="chart">Chart</div>
      <div className="buy-crypto">Buy Crypto</div>
    </>
  );
}
