import FlowIcon from "../../assets/images/dashboard/flow.png";
import { getNumberUnit } from "../../utils";

export default function CoinStatsCard(props) {
  const { className, value, title, unit } = props;

  return (
    <div className={`coin-stats-card m-4  ${className}`}>
      <h1 className="text-5xl xl:text-5xl mb-3">
        {unit}
        {getNumberUnit(value, 2)}
      </h1>
      <p className="text-3xl xl:text-xl mb-3">{title}</p>
      <img src={FlowIcon} alt="Flow icon" />
    </div>
  );
}
