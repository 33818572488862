import { useEffect, useState, useContext } from "react";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import nftContractAbi from "../assets/Abis/escrow-abi.json";
import { testnetTokens } from "../constant/tokens";
import Layout from "../components/Escrow/Layout";
import NavBar from "../components/Escrow/NavBar";
import getContractsAddress from "../web3/contractsAddress";
import { web3ModalContext } from "../components/Web3ModalProvider";
import {
  convertTimestampToDateTime,
  convertProductStateToStr,
  convertCryptoStateToStr,
  findCurrencyType,
  formatShortAddress,
  loadBulletinLists,
  genRandomString,
} from "../utils/utils";
import { Link } from "react-router-dom";
import Moment from "react-moment";

export default function EscrowDashboard() {
  const { web3Modal, web3Data, setWeb3Data, connectWallet, getContract, getFormatEther } = useContext(web3ModalContext);
  const escrowAddress = getContractsAddress(web3Data.chainId).escrowAddress;

  const [bulletinLists, setBulletinLists] = useState([]);
  const [productTrades, setProductTrades] = useState([]);
  const [serviceTrades, setServiceTrades] = useState([]);
  const [cryptoTrades, setCryptoTrades] = useState([]);

  const initEscrow = async () => {
    updateBulletinList();
    await updateProductList();
    await updateServiceList();
    await updateCryptoList();
  };

  const resetEscrow = () => {
    setBulletinLists([]);
    setProductTrades([]);
    setServiceTrades([]);
    setCryptoTrades([]);
  };

  const connect = async () => {
    try {
      const { provider, library, signer, account, network, chainId } = await connectWallet();
      setWeb3Data({ ...web3Data, provider, library, signer, account, connected: true, network, chainId });
      await initEscrow();
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (web3Modal?.cachedProvider) {
      connect();
    }
  }, []);

  useEffect(() => {
    if (web3Data.connected) {
      if (web3Data.signer) initEscrow();
    } else {
      resetEscrow();
    }
  }, [web3Data.connected]);

  const updateBulletinList = () => {
    loadBulletinLists()
      .then((bulletins) => {
        // console.log(bulletins);
        setBulletinLists(bulletins);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateProductList = async () => {
    try {
      let newArr = [];
      const contract = getContract(escrowAddress, nftContractAbi);
      const productTradeCount = await contract.currentProductTradeId();
      for (let i = 1; i <= productTradeCount; i++) {
        const productTrade = await contract.productTrades(i);
  
        newArr.push({
          productId: i,
          createTime: convertTimestampToDateTime(productTrade.createTime),
          buyer: formatShortAddress(productTrade.buyer),
          seller: formatShortAddress(productTrade.seller),
          price: getFormatEther(productTrade.price.toString()),
          currentState: convertProductStateToStr(productTrade.currentState),
        });
      }
      setProductTrades(newArr);  
    } catch(err) {
      console.log(err);
    }
  };

  const updateServiceList = async () => {
    let newArr = [];
    const contract = getContract(escrowAddress, nftContractAbi);
    const serviceTradeCount = await contract.currentServiceTradeId();
    for (let i = 1; i <= serviceTradeCount; i++) {
      const serviceTrade = await contract.serviceTrades(i);
      newArr.push({
        serviceId: i,
        createTime: convertTimestampToDateTime(serviceTrade.createTime),
        buyer: formatShortAddress(serviceTrade.buyer),
        seller: formatShortAddress(serviceTrade.seller),
        price: getFormatEther(serviceTrade.price.toString()),
        currentState: convertProductStateToStr(serviceTrade.currentState),
      });
    }
    setServiceTrades(newArr);
  };

  const updateCryptoList = async () => {
    let newArr = [];
    const contract = getContract(escrowAddress, nftContractAbi);
    const cryptoTradeCount = await contract.currentCryptoTradeId();
    for (let i = 1; i <= cryptoTradeCount; i++) {
      const cryptoTrade = await contract.cryptoTrades(i);

      newArr.push({
        cryptoId: i,
        createTime: convertTimestampToDateTime(cryptoTrade.createTime),
        buyer: cryptoTrade.buyer.search(/0x[0]{40}/) !== -1 ? "No Buyer" : formatShortAddress(cryptoTrade.buyer),
        seller: formatShortAddress(cryptoTrade.seller),
        currencyType: findCurrencyType(testnetTokens, cryptoTrade.currency),
        amount: parseFloat(cryptoTrade.amount.toString()) / Math.pow(10, parseInt(cryptoTrade.decimals.toString())),
        price: getFormatEther(cryptoTrade.price.toString()),
        currentState: convertCryptoStateToStr(cryptoTrade.completed),
      });
    }
    setCryptoTrades(newArr);
  };

  async function goToChatRoom(e, roomNo) {
    e.preventDefault();
    const suffix = genRandomString();
    window.open("/escrow/chat/" + roomNo + suffix, "_blank");
  }

  async function goToBulletin(e, id) {
    e.preventDefault();
    window.open("/escrow/postbulletin/" + id, "_blank");
  }

  return (
    <Layout>
      <NavBar></NavBar>
      <Stack direction="row" justifyContent="space-evenly" alignItems="center" spacing={8} className="margin-top-50">
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            "& > :not(style)": {
              m: 1,
              width: 256,
              height: 128,
            },
          }}
        >
          <Paper className="main-theme-color text-white flex-center">
            <Stack direction="row" justifyContent="space-evenly" alignItems="center" spacing={2}>
              <img src="/images/bitcoin.svg" alt="Total Volume Traded" className="width-75" />
              <Stack alignItems="center" spacing={2}>
                <div>Total Volume Traded</div>
                <div className="dashboard-overview-highlight">3,000,000 USD</div>
              </Stack>
            </Stack>
          </Paper>
          <Paper className="main-theme-color text-white flex-center">
            <Stack direction="row" justifyContent="space-evenly" alignItems="center" spacing={2}>
              <img src="/images/woman.png" alt="Total Participants" className="width-75" />
              <Stack alignItems="center" spacing={2}>
                <div>Total Participants</div>
                <div className="dashboard-overview-highlight">5,000</div>
              </Stack>
            </Stack>
          </Paper>
          <Paper className="main-theme-color text-white flex-center">
            <Stack direction="row" justifyContent="space-evenly" alignItems="center" spacing={2}>
              <img src="/images/rating-star.jpg" alt="Success Rating" className="width-75" />
              <Stack alignItems="center" spacing={2}>
                <div>Success Rating</div>
                <div className="dashboard-overview-highlight">96.5%</div>
              </Stack>
            </Stack>
          </Paper>
        </Box>
      </Stack>

      <Stack justifyContent="center" className="activity-table">
        <div className="activity-header font-bold">Recent Bullets</div>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table" className="main-theme-color">
            <TableHead>
              <TableRow>
                <TableCell className="text-white font-bold">Date/Time</TableCell>
                <TableCell align="right" className="text-white font-bold">
                  Type
                </TableCell>
                <TableCell align="right" className="text-white font-bold">
                  Title
                </TableCell>
                <TableCell align="right" className="text-white font-bold">
                  Details
                </TableCell>
                <TableCell align="right" className="text-white font-bold">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                web3Data.connected ? (
                  bulletinLists.length > 0 ? (
                    bulletinLists.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row" className="text-white">
                          <Moment>{row.created_at}</Moment>
                        </TableCell>
                        <TableCell align="right" className="text-white">
                          {row.type}
                        </TableCell>
                        <TableCell align="right" className="text-white">
                          {row.title}
                        </TableCell>
                        <TableCell align="right" className="text-white">
                          <Link to="#" className="text-white" onClick={(e) => goToBulletin(e, `${row.id}`)}>
                            Details
                          </Link>
                        </TableCell>
                        <TableCell align="right" className="text-white">
                          <Link to="#" className="text-white" onClick={(e) => goToChatRoom(e, `${row.room_no}`)}>
                            Join Chat
                          </Link>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow className="text-white dashboard-overview-highlight">
                      <TableCell align="center" className="text-white font-bold" colSpan={8}>
                        No Bulletin Lists yet
                      </TableCell>
                    </TableRow>
                  )
                ) : (
                  <TableRow className="text-white dashboard-overview-highlight">
                    <TableCell align="center" className="text-white font-bold" colSpan={8}>
                      Please connect your wallet
                    </TableCell>
                  </TableRow>
                )
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>

      <Stack justifyContent="center" className="activity-table">
        <div className="activity-header font-bold">Recent Activity on Product Trading</div>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table" className="main-theme-color">
            <TableHead>
              <TableRow>
                <TableCell className="text-white font-bold">Date/Time</TableCell>
                <TableCell align="right" className="text-white font-bold">
                  Type
                </TableCell>
                <TableCell align="right" className="text-white font-bold">
                  Buyer
                </TableCell>
                <TableCell align="right" className="text-white font-bold">
                  Seller
                </TableCell>
                <TableCell align="right" className="text-white font-bold">
                  Currency
                </TableCell>
                <TableCell align="right" className="text-white font-bold">
                  Amount
                </TableCell>
                <TableCell align="right" className="text-white font-bold">
                  Status
                </TableCell>
                <TableCell align="right" className="text-white font-bold">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                web3Data.connected ? (
                  productTrades.length > 0 ? (
                    productTrades.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row" className="text-white">
                          {row.createTime}
                        </TableCell>
                        <TableCell align="right" className="text-white">
                          Product
                        </TableCell>
                        <TableCell align="right" className="text-white">
                          {row.buyer}
                        </TableCell>
                        <TableCell align="right" className="text-white">
                          {row.seller}
                        </TableCell>
                        <TableCell align="right" className="text-white">
                          BNB
                        </TableCell>
                        <TableCell align="right" className="text-white">
                          {row.price}
                        </TableCell>
                        <TableCell align="right" className="text-white">
                          {row.currentState}
                        </TableCell>
                        <TableCell align="right" className="text-white">
                          <Link to={"/escrow/product/" + row.productId} className="text-white">
                            Details
                          </Link>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow className="text-white dashboard-overview-highlight">
                      <TableCell align="center" className="text-white font-bold" colSpan={8}>
                        No transactions yet
                      </TableCell>
                    </TableRow>
                  )
                ) : (
                  <TableRow className="text-white dashboard-overview-highlight">
                    <TableCell align="center" className="text-white font-bold" colSpan={8}>
                      Please connect your wallet
                    </TableCell>
                  </TableRow>
                )
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>

      <Stack justifyContent="center" className="activity-table">
        <div className="activity-header font-bold">Recent Activity on Service Trading</div>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table" className="main-theme-color">
            <TableHead>
              <TableRow>
                <TableCell className="text-white font-bold">Date/Time</TableCell>
                <TableCell align="right" className="text-white font-bold">
                  Type
                </TableCell>
                <TableCell align="right" className="text-white font-bold">
                  Buyer
                </TableCell>
                <TableCell align="right" className="text-white font-bold">
                  Seller
                </TableCell>
                <TableCell align="right" className="text-white font-bold">
                  Currency
                </TableCell>
                <TableCell align="right" className="text-white font-bold">
                  Amount
                </TableCell>
                <TableCell align="right" className="text-white font-bold">
                  Status
                </TableCell>
                <TableCell align="right" className="text-white font-bold">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                web3Data.connected ? (
                  serviceTrades.length > 0 ? (
                    serviceTrades.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row" className="text-white">
                          {row.createTime}
                        </TableCell>
                        <TableCell align="right" className="text-white">
                          Service
                        </TableCell>
                        <TableCell align="right" className="text-white">
                          {row.buyer}
                        </TableCell>
                        <TableCell align="right" className="text-white">
                          {row.seller}
                        </TableCell>
                        <TableCell align="right" className="text-white">
                          BNB
                        </TableCell>
                        <TableCell align="right" className="text-white">
                          {row.price}
                        </TableCell>
                        <TableCell align="right" className="text-white">
                          {row.currentState}
                        </TableCell>
                        <TableCell align="right" className="text-white">
                          <Link to={"/escrow/service/" + row.serviceId} className="text-white">
                            Details
                          </Link>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow className="text-white dashboard-overview-highlight">
                      <TableCell align="center" className="text-white font-bold" colSpan={8}>
                        No transactions yet
                      </TableCell>
                    </TableRow>
                  )
                ) : (
                  <TableRow className="text-white dashboard-overview-highlight">
                    <TableCell align="center" className="text-white font-bold" colSpan={8}>
                      Please connect your wallet
                    </TableCell>
                  </TableRow>
                )
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>

      <Stack justifyContent="center" className="activity-table">
        <div className="activity-header font-bold">Recent Activity on Crypto Trading</div>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table" className="main-theme-color">
            <TableHead>
              <TableRow>
                <TableCell className="text-white font-bold">Date/Time</TableCell>
                <TableCell align="right" className="text-white font-bold">
                  Type
                </TableCell>
                <TableCell align="right" className="text-white font-bold">
                  Buyer
                </TableCell>
                <TableCell align="right" className="text-white font-bold">
                  Seller
                </TableCell>
                <TableCell align="right" className="text-white font-bold">
                  Currency
                </TableCell>
                <TableCell align="right" className="text-white font-bold">
                  Amount
                </TableCell>
                <TableCell align="right" className="text-white font-bold">
                  Price
                </TableCell>
                <TableCell align="right" className="text-white font-bold">
                  Status
                </TableCell>
                <TableCell align="right" className="text-white font-bold">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                web3Data.connected ? (
                  cryptoTrades.length > 0 ? (
                    cryptoTrades.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row" className="text-white">
                          {row.createTime}
                        </TableCell>
                        <TableCell align="right" className="text-white">
                          Crypto
                        </TableCell>
                        <TableCell align="right" className="text-white">
                          {row.buyer}
                        </TableCell>
                        <TableCell align="right" className="text-white">
                          {row.seller}
                        </TableCell>
                        <TableCell align="right" className="text-white">
                          {row.currencyType}
                        </TableCell>
                        <TableCell align="right" className="text-white">
                          {row.amount}
                        </TableCell>
                        <TableCell align="right" className="text-white">
                          {row.price}
                        </TableCell>
                        <TableCell align="right" className="text-white">
                          {row.currentState}
                        </TableCell>
                        <TableCell align="right" className="text-white">
                          <Link to={"/escrow/cryptotrading/" + row.cryptoId} className="text-white">
                            Details
                          </Link>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow className="text-white dashboard-overview-highlight">
                      <TableCell align="center" className="text-white font-bold" colSpan={9}>
                        No transactions yet
                      </TableCell>
                    </TableRow>
                  )
                ) : (
                  <TableRow className="text-white dashboard-overview-highlight">
                    <TableCell align="center" className="text-white font-bold" colSpan={9}>
                      Please connect your wallet
                    </TableCell>
                  </TableRow>
                )
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </Layout>
  );
}
