import { useEffect, useContext, useState } from "react";
import { Link } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import toast from "react-hot-toast";
import { getLotteryContract } from "../../web3/getLotteryContract";
import { TicketNumberContext } from "../../pages/Lottery";
import TimerComponent from "../Timer";
import { web3ModalContext } from "../Web3ModalProvider";
import BannerLeft from "../../assets/images/lottery/banner_left.png";
import BannerRight from "../../assets/images/lottery/banner_right.png";
import LotteryLogo from "../../assets/images/lottery/lottery_logo.png";
import TicketLogo from "../../assets/images/lottery/ticket_logo.png";

export default function LotteryDashboard() {
  const { INITIAL_STATE, web3Modal, web3Data, setWeb3Data, connectWallet, disconnectWallet } = useContext(web3ModalContext);
  const { ticketCount, setTicketCount } = useContext(TicketNumberContext);
  const lotteryContract = getLotteryContract();
  const [currentLottery, setCurrentLottery] = useState(null);

  const connect = async () => {
    try {
      const { provider, library, signer, account, network, chainId } = await connectWallet();
      setWeb3Data({ ...web3Data, connected: true, provider, library, signer, account, network, chainId });
    } catch (err) {
      console.log(err);
    }
  };

  const disconnect = async () => {
    try {
      await disconnectWallet();
      setWeb3Data({...INITIAL_STATE});
    } catch (err) {
      console.log(err);
    }
  };

  const renderButton = (
    <>
      {web3Data.connected ? (
        <button className="connect-wallet" onClick={disconnect}>
          {web3Data.account.substring(0, 7) + " ... " + web3Data.account.substring(36)}
        </button>
      ) : (
        <button className="connect-wallet" onClick={connect}>
          CONNECT WALLET
        </button>
      )}
    </>
  );

  const getCurrentLottery = async () => {
    try {
      const lotteryId = await lotteryContract.currentLotteryId();
      const lotteryInfo = await lotteryContract.viewLottery(lotteryId);
      const userInfo = await lotteryContract.viewUserInfoForLotteryId(web3Data.account, lotteryId, 0, 1000);
      const lottery = {
        id: lotteryId,
        startTime: lotteryInfo.startTime,
        endTime: lotteryInfo.endTime,
        amountCollected: lotteryInfo.amountCollected,
        ticketCount: lotteryInfo.firstTicketIdNextLottery - lotteryInfo.firstTicketId,
        finalNumber: lotteryInfo.finalNumber,
        status: lotteryInfo.status,
        tickets: userInfo[1],
        ticketsid: userInfo[0],
        ticketsStats: userInfo[2],
      };
      console.log(lottery);
      setCurrentLottery(lottery);
    } catch (err) {
      console.log(err);
    }
  };

  const handleTicketNumber = (number) => {
    setTicketCount(number);
  };

  useEffect(() => {
    if (web3Modal?.cachedProvider) {
      connect();
    }
  }, []);

  useEffect(() => {
    if (web3Data.connected) {
      if (web3Data.signer) getCurrentLottery();
    }
  }, [web3Data.connected]);

  return (
    <div className="lottery-dashboard">
      <div className="grid lg:grid-cols-2 xl:grid-cols-8 gap-4 p-4">
        {/* <h5 className="absolute right-5">Rule</h5> */}
        <div className="banner-left lg:col-span-1 xl:col-span-3">
          <h3 className="text-center lg:text-left mb-6">Aeterna Lottery</h3>
          <div className="flex justify-center justify-center">
            <div className="lottery-prize my-3">
              <h4 className="prize-title">Prize</h4>
              <h1 className="prize-value text-4xl sm:text-5xl xl:text-6xl 2xl:text-7xl">$25,458.48</h1>
              {renderButton}
            </div>
          </div>
        </div>
        <div className="lottery-logo hidden xl:col-span-2 xl:flex items-center justify-center ">
          <div className="flex flex-col items-center w-full">
            <img src={LotteryLogo} alt="Lottery Logo" />
            <Link to="pick/ticket" className="buy-ticket-btn" state={{ ticketsCount: 1 }}>
              Buy Ticket
            </Link>
          </div>
        </div>
        <div className="banner-right lg:col-span-1 xl:col-span-3">
          <div className="flex flex-row mt-4">
            <div className="flex flex-col items-center w-full">
              <h5>Get Your Tickets Now!</h5>
              <h4 className="text-lg">{currentLottery ? `Round ${currentLottery.id}` : ""}</h4>
              <div className="ticket-time-track">
                <TimerComponent className="" requestLastTime={new Date(currentLottery?.endTime)} />
              </div>
              <div className="ticket-logo flex flex-col items-center">
                <div className="relative flex justify-between align-center">
                  <img src={TicketLogo} alt="Ticket Logo" />
                  <div className="buy-ticket-number">
                    <p>Ticket number</p>
                    <input
                      type="number"
                      placeholder="0"
                      min={0}
                      max={9999999}
                      onChange={(e) => handleTicketNumber(e.target.value)}
                    />
                  </div>
                </div>
                <Link to="pick/ticket" state={{ ticketsCount: ticketCount }}>
                  Buy Ticket
                </Link>
                {/* <button>Buy Tickets</button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2 xl:grid-cols-8 gap-4">
        <div className="col-span-1 xl:col-span-3">
          <img src={BannerLeft} alt="Banner logo" />
        </div>
        <div className="col-span-1 xl:col-start-6 xl:col-span-3">
          <img src={BannerRight} alt="Banner logo" />
        </div>
      </div>
    </div>
  );
}
