import { useContext } from "react";
import { BsDice5 } from "react-icons/bs";
import { TbEraser } from "react-icons/tb";
import { MdHelpOutline } from "react-icons/md";
import { FaRegCalendarAlt } from "react-icons/fa";
import { PickNumberContext } from "../../pages/PickTicket";
import NumberPanel from "./NumberPanel";
import { getRandomInt } from "../../utils";
import { v4 as uuid } from "uuid";

export default function ChooseNumber() {
  const { pickedNum, setPickedNum, ticketCount } = useContext(PickNumberContext);

  const clearAll = () => {
    setPickedNum([]);
  };

  const autofillAll = () => {
    let list = pickedNum;

    if (ticketCount <= pickedNum.length) return;
    for (let i = 0; i < ticketCount - pickedNum.length; i++) {
      const id = uuid().slice(0, 8);
      let random = [];
      let count = 0;
      while (count < 6) {
        const randomInt = getRandomInt(1, 65);
        const index = random.indexOf(randomInt);
        if (index > -1) continue;
        else random.push(randomInt);
        count++;
      }
      const addTicket = { id: id, pick: random };
      list = [...list, addTicket];
      // list.push(addTicket);
      console.log(list);
      setPickedNum([...list]);
    }
  };

  return (
    <div className="choose-number">
      <div className="headline">
        <button className="flex justify-start items-center px-1 rounded border" onClick={clearAll}>
          <TbEraser style={{ fontSize: "17px", color: "#707070", marginRight: "4px" }} />
          <p>CLEAR ALL</p>
        </button>
        <button className="flex justify-start items-center px-1 mx-3 rounded border" onClick={autofillAll}>
          <BsDice5 style={{ fontSize: "17px", color: "#707070", marginRight: "4px" }} />
          <p>AUTO-FILL ALL</p>
        </button>
        <button className="flex justify-start items-center px-1 rounded border">
          <MdHelpOutline style={{ fontSize: "17px", color: "#707070", marginRight: "4px" }} />
          <p>HOW TO PLAY?</p>
        </button>
      </div>
      <div className="number-panel">
        <div className="panel-header">
          <div className="flex justify-start items-center">
            <FaRegCalendarAlt style={{ fontSize: "25px", color: "#11bc56", marginRight: "14px" }} />
            <h3>CHOOSE 6 NUMBERS</h3>
          </div>
          <div className="flex justify-end items-center">
            <button className="rounded p-2 mr-2 bg-[#3dff8b]">
              <BsDice5 style={{ fontSize: "17px", color: "#707995" }} />
            </button>
            <button className="rounded p-2 bg-[#5338bc]">
              <TbEraser style={{ fontSize: "17px", color: "#707995" }} />
            </button>
          </div>
        </div>
        <div className="panel-body">
          <NumberPanel />
        </div>
      </div>
    </div>
  );
}
