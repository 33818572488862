import { useState, useEffect, useContext } from "react";
import { Oval } from "react-loader-spinner";
import toast from "react-hot-toast";
import getContractsAddress from "../../web3/contractsAddress";
import PancakeswapFactory from "../../web3/abi/PancakeswapFactory.json";
import PancakeswapRouter from "../../web3/abi/PancakeswapRouter.json";
import { MdArrowDownward } from "react-icons/md";
import { TbRefresh } from "react-icons/tb";
import { BsQuestionCircle } from "react-icons/bs";
import { web3ModalContext } from "../Web3ModalProvider";
import { notify } from "../../utils";
import SwapInput from "./SwapInput";
import { testnetTokens } from "../../constant/tokens";
import tokenList from "../../web3/pancakeswap-top-15";
import Lp_abi from "../../web3/abi/Lp_abi.json";
import tokenAbi from "../../web3/abi/tokenAbi.json";
import AeternaLogo from "../../assets/images/common/logo.png";
import { BSCSCAN_EXPLORER, SWAP_POSITION, BSC_Mainnet, BSC_Testnet } from "../../config";

export default function SwapBody({ slippage }) {
  const { web3Modal, web3Data, setWeb3Data, connectWallet, getContract, getBalance,
    getFormatUnits, getFormatEther, getBignumberFrom, getBlockTimestamp } = useContext(web3ModalContext);
  const routerAddress = getContractsAddress(web3Data.chainId).PancakeswapRouter;

  const [currency0Address, setCurrency0Address] = useState(testnetTokens[0].address);
  const [currency1Address, setCurrency1Address] = useState(web3Data.chainId == BSC_Mainnet ? getContractsAddress(web3Data.chainId).tokenAddress : testnetTokens[2].address);
  const [currency1, setCurrency1] = useState(null);
  const [currency0, setCurrency0] = useState(null);
  const [currency0Amount, setCurrency0Amount] = useState(0);
  const [currency1Amount, setCurrency1Amount] = useState(0);
  const [successSwap, setSuccessSwap] = useState(false);
  const [swapLoading, setSwapLoading] = useState(false);
  const [currency0Loading, setCurrency0Loading] = useState(false);
  const [currency1Loading, setCurrency1Loading] = useState(false);
  const [token0Overflow, setToken0Overflow] = useState(false);
  const [token1Overflow, setToken1Overflow] = useState(false);
  const [approveToken, setApproveToken] = useState([]);
  const [direction, setDirection] = useState(SWAP_POSITION.FROM);

  const initTokens = () => {
    setCurrency0Address(testnetTokens[0].address);
    setCurrency1Address(web3Data.chainId == BSC_Mainnet ? getContractsAddress(web3Data.chainId).tokenAddress : testnetTokens[2].address);
    setCurrency1(null);
    setCurrency0(null);
    setCurrency0Amount(0);
    setCurrency1Amount(0);
    setApproveToken([]);
    setDirection(SWAP_POSITION.FROM);
  };

  const swapToken = () => {
    setCurrency1Address(currency0Address);
    setCurrency0Address(currency1Address);
    setCurrency0Amount(0);
    setCurrency1Amount(0);
  };

  const getBNBBalance = async () => {
    const balance = await getBalance();
    return balance;
  };

  const getTokenInfo = async (address) => {
    try {
      const tokenContract = getContract(address, tokenAbi);
      const tokenBalance = await tokenContract.balanceOf(web3Data.account);
      const tokenTotalSupply = await tokenContract.totalSupply();
      const tokenSymbol = await tokenContract.symbol();
      const tokenName = await tokenContract.name();
      const tokenDecimals = await tokenContract.decimals();
      const tokenAllowance = await tokenContract.allowance(web3Data.account, routerAddress);
      let tokenLogo = undefined;

      if (web3Data.chainId === BSC_Mainnet) {
        const token = tokenList.filter((token) => token.address === address);
        tokenLogo = token[0].logoURI;
      } else if (web3Data.chainId === BSC_Testnet) {
        const token = testnetTokens.filter((token) => token.address === address);
        tokenLogo = token[0].logoURI;
      }

      return {
        contract: tokenContract,
        address: address,
        name: tokenName,
        symbol: tokenSymbol,
        balance: tokenBalance,
        decimals: tokenDecimals,
        totalSupply: tokenTotalSupply,
        allowance: tokenAllowance,
        logoURI: tokenLogo,
      };
    } catch (err) {
      console.log("getTokenInfo is failed for " + address + ".\n", err);
    }
  };

  const getToken = async (address, pos) => {
    pos === SWAP_POSITION.FROM ? setCurrency0Loading(true) : setCurrency1Loading(true);
    try {
      if (address === testnetTokens[0].address) {
        // BNB
        const balance = await getBNBBalance();
        const currency = {
          name: testnetTokens[0].name,
          symbol: testnetTokens[0].symbol,
          balance: balance,
          decimals: testnetTokens[0].decimals,
          address: testnetTokens[0].address,
          totalSupply: undefined,
          logoURI: testnetTokens[0].logoURI,
        };
        pos === SWAP_POSITION.FROM ? setCurrency0(currency) : setCurrency1(currency);
      } else {
        // Token
        const currency = await getTokenInfo(address);
        // console.log("=====>\n", currency);
        pos === SWAP_POSITION.FROM ? setCurrency0(currency) : setCurrency1(currency);

        if (getBignumberFrom(currency.allowance).lt(getFormatUnits(currency.totalSupply.toString()))) {
          const token = approveToken.filter((token) => token.symbol !== currency.symbol);
          setApproveToken([...token, currency]);
        }
      }
    } catch (err) {
      console.log(`getToken by ${pos === SWAP_POSITION.FROM ? "FROM" : "TO"} is failed.\n`, err);
      pos === SWAP_POSITION.FROM ? setCurrency0Loading(false) : setCurrency1Loading(false);
    }
    pos === SWAP_POSITION.FROM ? setCurrency0Loading(false) : setCurrency1Loading(false);
  };

  const getPair = async (address0, address1) => {
    try {
      let address00 = address0 === testnetTokens[0].address ? getContractsAddress(web3Data.chainId).WBNBAddress : address0;
      let address11 = address1 === testnetTokens[0].address ? getContractsAddress(web3Data.chainId).WBNBAddress : address1;

      const factoryContract = getContract(getContractsAddress(web3Data.chainId).PancakeswapFactory, PancakeswapFactory);
      // console.log(address00, address11, factoryContract,);
      const pairAddress = await factoryContract.getPair(address00, address11);
      const pairContract = getContract(pairAddress, Lp_abi);
      const lpBalance = await pairContract.balanceOf(web3Data.account);
      const lpTotalSupply = await pairContract.totalSupply();
      const poolShare = parseFloat((lpBalance / lpTotalSupply) * 100).toFixed(2);
      const token0 = await getTokenInfo(await pairContract.token0());
      const token1 = await getTokenInfo(await pairContract.token1());
      const reserve = await pairContract.getReserves();

      // console.log(token0, token1);
      return {
        pairAddress: pairAddress,
        lpBalance,
        lpTotalSupply,
        reserve,
        token0,
        token1,
        poolShare,
      };
    } catch (err) {
      console.log("getPair is failed.\n", err);
    }
  };

  const swapETH4Aeterna = async (contract, path, amount0, amount1, deadline) => {
    let result = {};
    const timestamp = await getBlockTimestamp();

    if (direction === SWAP_POSITION.FROM) {
      const amount1Min = await handle0Amount(amount0); // token2ETH = positive
      result = await contract.swapExactETHForTokens(
        // complete
        getBignumberFrom(amount1Min)
          .mul(100 - slippage)
          .div(100),
        path,
        web3Data.account,
        timestamp + 1200,
        { value: getFormatUnits(parseFloat(amount0).toFixed(18).toString()) }
      );
    } else if (direction === SWAP_POSITION.TO) {
      // complete
      const amount0Max = await handle1Amount(amount1);
      console.log(path, amount1, amount0Max);
      result = await contract.swapETHForExactTokens(
        getBignumberFrom(amount1)
          .mul(100 - slippage)
          .div(100),
        path,
        web3Data.account,
        timestamp + 1200,
        { value: amount0Max.toString() }
      );
    }

    return result;
  };

  const swapAeterna4ETH = async (contract, path, amount0, amount1, deadline) => {
    let result = {};
    const timestamp = await getBlockTimestamp();

    if (direction === SWAP_POSITION.FROM) {
      const amount1Min = await handle0Amount(amount0);
      result = await contract.swapExactTokensForETH(
        // complete
        getFormatUnits(parseFloat(amount0).toFixed(18).toString()),
        getBignumberFrom(amount1Min)
          .mul(100 - slippage)
          .div(100),
        path,
        web3Data.account,
        timestamp + 1200
      );
    } else if (direction === SWAP_POSITION.TO) {
      // complete
      const amount0Max = await handle1Amount(amount1);
      result = await contract.swapTokensForExactETH(
        getFormatUnits(parseFloat(amount1).toFixed(18).toString()),
        getBignumberFrom(amount0Max)
          .mul(100 - slippage)
          .div(100),
        path,
        web3Data.account,
        timestamp + 1200
      );
    }
    return result;
  };

  const swapAeterna4Token = async (contract, path, amount0, amount1, deadline) => {
    let result = {};
    const timestamp = await getBlockTimestamp();

    if (direction === SWAP_POSITION.FROM) {
      const amount1Min = await handle0Amount(amount0);
      // console.log("swapAeterna4Token\n", amount0, amount1Min)
      result = await contract.swapExactTokensForTokens(
        // writing
        getFormatUnits(parseFloat(amount0).toFixed(18).toString()),
        getBignumberFrom(amount1Min)
          .mul(100 - slippage)
          .div(100),
        path,
        web3Data.account,
        timestamp + 1200
      );
    } else if (direction === SWAP_POSITION.TO) {
      // todo
      const amount0Min = await handle1Amount(amount1);
      console.log("swapAeterna4Token\n", amount0Min);
      result = await contract.swapExactTokensForTokens(
        getFormatUnits(parseFloat(amount1).toFixed(18).toString()),
        getBignumberFrom(amount0Min)
          .mul(100 - slippage)
          .div(100),
        path,
        web3Data.account,
        timestamp + 1200
      );
    }

    return result;
  };

  const getTokens = async () => {
    try {
      if (currency0Address !== "") {
        await getToken(currency0Address, SWAP_POSITION.FROM);
      }
      if (currency1Address !== "") {
        await getToken(currency1Address, SWAP_POSITION.TO);
      }
      if (currency0Address !== "" && currency1Address !== "") {
        await getPair(currency0Address, currency1Address);
      }
    } catch (err) {
      console.log("getTokens is failed.\n", err);
    }
  };

  const connect = async () => {
    try {
      const { provider, library, signer, account, network, chainId } = await connectWallet();
      setWeb3Data({ ...web3Data, provider, library, signer, account, connected: true, network, chainId });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (web3Modal?.cachedProvider) {
      connect();
    }
  }, []);

  useEffect(() => {
    if (web3Data.connected) {
      if (web3Data.signer) getTokens();
    } else {
      initTokens();
    }
  }, [web3Data.connected, currency0Address, currency1Address]);

  const handleApprove = async () => {
    try {
      if (approveToken.length <= 0) {
        toast.error("approveToken is empty.");
        return;
      } else if (approveToken.length > 0) {
        setApproveLoading(true);
        for (let i = 0; i < approveToken.length; i++) {
          const approve = await approveToken[i].contract.approve(routerAddress, MAX_NUMBER);
          const remoeToken = approveToken.slice(i, 1);
          setApproveToken(remoeToken);
          notify({
            text: "Approve " + approveToken[i].symbol,
            link: `${process.env.REACT_APP_BSCSCAN_EXPLORER}/tx/${approve.hash}`,
          });
        }
        setApproveLoading(false);
      }
    } catch (err) {
      console.log(err);
      notify({
        text: "Approve " + approveToken[0]?.symbol + " " + approveToken[0]?.symbol + "is failed.",
      });
      setApproveLoading(false);
    }
  };

  const handleSwap = async () => {
    setSwapLoading(true);
    try {
      const routerContract = getContract(routerAddress, PancakeswapRouter);

      let result = {};

      if (currency0Address === testnetTokens[0].address) {
        const path = [getContractsAddress(web3Data.chainId).WBNBAddress, currency1Address];
        result = await swapETH4Aeterna(routerContract, path, currency0Amount, currency1Amount, 0);
      } else if (currency1Address === testnetTokens[0].address) {
        const path = [currency0Address, getContractsAddress(web3Data.chainId).WBNBAddress];
        result = await swapAeterna4ETH(routerContract, path, currency0Amount, currency1Amount, 0);
      } else {
        const path = [currency0Address, currency1Address];
        result = await swapAeterna4Token(routerContract, path, currency0Amount, currency1Amount, 0);
      }

      setCurrency0Amount(0);
      setCurrency1Amount(0);
      notify({
        text: `Swap ${currency0Amount} ${currency0.symbol} for ${currency1Amount} ${currency1.symbol}`,
        link: `${process.env.REACT_APP_BSCSCAN_EXPLORER}/tx/${result.hash}`,
      });
      const timer = setTimeout(() => {
        getTokens();
      }, 4000);

      setSwapLoading(false);
    } catch (err) {
      setSwapLoading(false);
      console.log("handleSwap is failed.\n", err);
    }
  };

  const handle0Amount = async (amount) => {
    try {
      let amount1Min = 0;
      const routerContract = getContract(routerAddress, PancakeswapRouter);

      if (amount !== undefined || amount !== "" || amount > 0) {
        setDirection(SWAP_POSITION.FROM);
        setCurrency0Amount(amount);

        const path =
          currency0Address === testnetTokens[0].address
            ? [getContractsAddress(web3Data.chainId).WBNBAddress, currency1Address]
            : currency1Address === testnetTokens[0].address
            ? [currency0Address, getContractsAddress(web3Data.chainId).WBNBAddress]
            : [currency0Address, currency1Address];
        amount1Min = (await routerContract.getAmountsOut(getFormatUnits(amount), path))[1];
        setCurrency1Amount(getFormatEther(amount1Min));

        return amount1Min;
      }
    } catch (err) {
      console.log("handle0Amount is failed.\n", err);
    }
  };

  const handle1Amount = async (amount) => {
    try {
      let amount0 = 0;
      const routerContract = getContract(routerAddress, PancakeswapRouter);

      if (amount !== undefined || amount !== "" || amount > 0) {
        setDirection(SWAP_POSITION.TO);
        setCurrency1Amount(amount);

        const path =
          currency0Address === testnetTokens[0].address
            ? [getContractsAddress(web3Data.chainId).WBNBAddress, currency1Address]
            : currency1Address === testnetTokens[0].address
            ? [currency0Address, getContractsAddress(web3Data.chainId).WBNBAddress]
            : [currency0Address, currency1Address];
        amount0 = (await routerContract.getAmountsIn(getFormatUnits(amount), path))[0];
        setCurrency0Amount(getFormatEther(amount0));

        return amount0;
      }
    } catch (err) {
      console.log("handle1Amount is failed.\n", err);
    }
  };

  return (
    <div className="swap-body">
      <div className="swap-from">
        <SwapInput
          tokenInfo={currency0}
          loading={currency0Loading}
          overflow={(flag) => setToken0Overflow(flag)}
          value={currency0Amount}
          direction={SWAP_POSITION.FROM}
          handleToken={(token) => {
            setCurrency0(token);
            setCurrency0Address(token.address);
          }}
          handleAmount={async (amount) => await handle0Amount(amount)}
        />
      </div>

      {/* exchange token button */}
      <button className="reverse-token" onClick={() => swapToken()}>
        <MdArrowDownward style={{ width: "24px", height: "24px", color: "#fff" }} />
      </button>

      <div className="swap-to">
        <SwapInput
          tokenInfo={currency1}
          loading={currency1Loading}
          overflow={(flag) => setToken1Overflow(flag)}
          value={currency1Amount}
          direction={SWAP_POSITION.TO}
          handleToken={(token) => {
            setCurrency1(token);
            setCurrency1Address(token.address);
          }}
          handleAmount={async (amount) => await handle1Amount(amount)}
        />
      </div>
      <div className="flex flex-row justify-between">
        <p className="text-white text-base">Slippage Tolerance</p>
        <p className="text-sky-400">0.5%</p>
      </div>
      {web3Data.connected ? (
        currency0Amount > 0 ? (
          <div className="flex flex-row justify-between mb-2">
            <p className="text-white text-base">Price</p>
            <div className="flex flex-row justify-around items-center">
              <p className="text-white text-base">0.000485 BNB Per Aeterna</p>
              <TbRefresh
                style={{
                  width: "18px",
                  height: "18px",
                  color: "#fff",
                  marginLeft: "2px",
                }}
              />
            </div>
          </div>
        ) : (
          <></>
        )
      ) : (
        <></>
      )}
      {web3Data.connected ? (
        currency0Amount > 0 && currency1Amount > 0 ? (
          <>
            <div className="flex flex-row justify-between">
              {approveToken.length > 0 ? (
                <button className="exchange-button mr-5" onClick={handleApprove}>
                  Enable {approveToken[0]?.symbol} {approveToken[1]?.symbol}
                </button>
              ) : (
                <></>
              )}
              <button className="exchange-button" disabled={swapLoading} onClick={handleSwap}>
                {swapLoading && (
                  <Oval
                    height={16}
                    width={16}
                    color="#ffffff"
                    wrapperStyle={{ marginRight: "8px" }}
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="transparent"
                    strokeWidth={4}
                    strokeWidthSecondary={4}
                  />
                )}
                Swap
              </button>
            </div>
            <div className="swap-info">
              <div className="flex flex-row justify-between">
                <div className="flex flex-row justify-start items-center">
                  <p className="mr-2">Minimum received</p>
                  <BsQuestionCircle />
                </div>
                <p>4850 AETERNA V2</p>
              </div>
              <div className="flex flex-row justify-between">
                <div className="flex flex-row justify-start items-center">
                  <p className="mr-2">Price Impact</p>
                  <BsQuestionCircle />
                </div>
                <p className="text-sky-400">0.16%</p>
              </div>
              <div className="flex flex-row justify-between">
                <div className="flex flex-row justify-start items-center">
                  <p className="mr-2">Liquidity Provider Fee</p>
                  <BsQuestionCircle />
                </div>
                <p>0.000157BNB</p>
              </div>
              <div className="flex flex-row justify-between">
                <div className="flex flex-row justify-start items-center">
                  <p className="mr-2">Route</p>
                  <BsQuestionCircle />
                </div>
                <p>BNB {">"} AETERNA</p>
              </div>
            </div>
          </>
        ) : (
          <button className="enter-button">Enter an amount</button>
        )
      ) : (
        <button className="connect-button" onClick={connect}>
          Connect Wallet
        </button>
      )}
    </div>
  );
}
