import Slider from "react-slick";
import News1 from "../../assets/images/home/News1.jpg";
import News2 from "../../assets/images/home/News2.jpg";
import News3 from "../../assets/images/home/News3.jpg";

export default function NewsCarousel() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="news px-8 mx-auto">
      <Slider {...settings}>
        <div className="news-item">
          <div className="news-item-container">
            <img src={News1} alt="New Item" />
            <button className="news-connect-btn">Twitter</button>
          </div>
        </div>
        <div className="news-item">
          <div className="news-item-container">
            <img src={News2} alt="New Item" />
            <button className="news-connect-btn">Twitter</button>
          </div>
        </div>
        <div className="news-item">
          <div className="news-item-container">
            <img src={News3} alt="New Item" />
            <button className="news-connect-btn">Twitter</button>
          </div>
        </div>
        <div className="news-item">
          <div className="news-item-container">
            <img src={News1} alt="New Item" />
            <button className="news-connect-btn">Twitter</button>
          </div>
        </div>
        <div className="news-item">
          <div className="news-item-container">
            <img src={News2} alt="New Item" />
            <button className="news-connect-btn">Twitter</button>
          </div>
        </div>
        <div className="news-item">
          <div className="news-item-container">
            <img src={News3} alt="New Item" />
            <button className="news-connect-btn">Twitter</button>
          </div>
        </div>
      </Slider>
    </div>
  );
}
