import { toast } from "react-toastify";
import { MdOpenInNew } from "react-icons/md";
import { BsCheck2Circle } from "react-icons/bs";

const Message = ({ text, link }) => {
  return (
    <div className="flex flex-row justify-between">
      <BsCheck2Circle
        style={{
          color: "#9e1159",
          width: "24px",
          height: "24px",
          marginRight: "8px",
        }}
      />
      <div className="content pr-10">
        <p className="text-white mb-2">{text}</p>
        <a href={link} target="blank" className="flex flex-row items-center hover:underline">
          View on explorer <MdOpenInNew style={{ color: "#9e1159", marginLeft: "4px" }} />
        </a>
      </div>
    </div>
  );
};

export const notify = ({ text, link }) => {
  toast(<Message text={text} link={link} />);
};

export const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
};

export const getNumberUnit = (num, round = 1) => {
  const unit = Math.floor(
      Math.round(num / 1.0e1)
        .toLocaleString()
        .replaceAll(",", "").length
    ),
    wunit = ["K", "M", "B", "T"][Math.floor(unit / 3) - 1],
    funit = Math.abs(Number(num)) / Number("1.0e+" + (unit - (unit % 3)));
  return wunit ? funit.toFixed(round).toLocaleString() + " " + wunit : num.toFixed(round).toString();
};
