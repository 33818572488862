import PlayCard from "./PlayCard";
import TicketsLogo from "../../assets/images/lottery/tickets.png";
import DrawLogo from "../../assets/images/lottery/draw.png";
import ChestLogo from "../../assets/images/lottery/chest.png";

export default function HowtoPlay() {
  return (
    <div className="how-to-play">
      <h3>HOW TO PLAY</h3>
      <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-1 lg:grid-cols-3 gap-8 mt-8">
        <PlayCard className="buy-ticket">
          <img src={TicketsLogo} alt="Buy Ticket" />
          <h3>BUY TICKETS</h3>
          <p>Buy ticket with $5 and choose numbers for ticket</p>
        </PlayCard>
        <PlayCard className="wait-draw">
          <img src={DrawLogo} alt="Draw" />
          <h3>WAIT FOR THE DRAW</h3>
          <p>Wait for the draw at 15:00 UTC+0 daily.</p>
        </PlayCard>
        <PlayCard className="check-prize">
          <img src={ChestLogo} alt="Prize" />
          <h3>CHECK FOR PRIZES</h3>
          <p>Once the draw is over, come back to this page and check your prize.</p>
        </PlayCard>
      </div>
    </div>
  );
}
