export default {
  name: "PancakeSwap Top 15",
  timestamp: "2022-05-28T04:52:49.636Z",
  logoURI: "https://pancakeswap.finance/logo.png",
  tokens: [
    {
      name: "BNB",
      symbol: "BNB",
      address: "",
      chainId: 56,
      decimals: 18,
      logoURI: "https://tokens.pancakeswap.finance/images/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c.png",
    },
    {
      name: "Cardano Token",
      symbol: "ADA",
      address: "0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47",
      chainId: 56,
      decimals: 18,
      logoURI: "https://tokens.pancakeswap.finance/images/0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47.png",
    },
    {
      name: "AlpacaToken",
      symbol: "ALPACA",
      address: "0x8F0528cE5eF7B51152A59745bEfDD91D97091d2F",
      chainId: 56,
      decimals: 18,
      logoURI:
        "https://assets-cdn.trustwallet.com/blockchains/smartchain/assets/0x8F0528cE5eF7B51152A59745bEfDD91D97091d2F/logo.png",
    },
    {
      name: "BELT Token",
      symbol: "BELT",
      address: "0xE0e514c71282b6f4e823703a39374Cf58dc3eA4f",
      chainId: 56,
      decimals: 18,
      logoURI: "https://tokens.pancakeswap.finance/images/0xE0e514c71282b6f4e823703a39374Cf58dc3eA4f.png",
    },
    {
      name: "Bunny Token",
      symbol: "BUNNY",
      address: "0xC9849E6fdB743d08fAeE3E34dd2D1bc69EA11a51",
      chainId: 56,
      decimals: 18,
      logoURI:
        "https://assets-cdn.trustwallet.com/blockchains/smartchain/assets/0xC9849E6fdB743d08fAeE3E34dd2D1bc69EA11a51/logo.png",
    },
    {
      name: "Dai Token",
      symbol: "DAI",
      address: "0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3",
      chainId: 56,
      decimals: 18,
      logoURI:
        "https://assets-cdn.trustwallet.com/blockchains/smartchain/assets/0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3/logo.png",
    },
    {
      name: "Polkadot Token",
      symbol: "DOT",
      address: "0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402",
      chainId: 56,
      decimals: 18,
      logoURI:
        "https://assets-cdn.trustwallet.com/blockchains/smartchain/assets/0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402/logo.png",
    },
    {
      name: "MemePad",
      symbol: "MEPAD",
      address: "0x9d70a3EE3079A6FA2bB16591414678b7Ad91f0b5",
      chainId: 56,
      decimals: 18,
      logoURI:
        "https://assets-cdn.trustwallet.com/blockchains/smartchain/assets/0x9d70a3EE3079A6FA2bB16591414678b7Ad91f0b5/logo.png",
    },
    {
      name: "TRONPAD.network",
      symbol: "TRONPAD",
      address: "0x1Bf7AedeC439D6BFE38f8f9b20CF3dc99e3571C4",
      chainId: 56,
      decimals: 18,
      logoURI:
        "https://assets-cdn.trustwallet.com/blockchains/smartchain/assets/0x1Bf7AedeC439D6BFE38f8f9b20CF3dc99e3571C4/logo.png",
    },
    {
      name: "USD Coin",
      symbol: "USDC",
      address: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
      chainId: 56,
      decimals: 18,
      logoURI:
        "https://assets-cdn.trustwallet.com/blockchains/smartchain/assets/0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d/logo.png",
    },
    {
      name: "Wrapped UST Token",
      symbol: "UST",
      address: "0x23396cF899Ca06c4472205fC903bDB4de249D6fC",
      chainId: 56,
      decimals: 18,
      logoURI:
        "https://assets-cdn.trustwallet.com/blockchains/smartchain/assets/0x23396cF899Ca06c4472205fC903bDB4de249D6fC/logo.png",
    },
    {
      name: "VAI Stablecoin",
      symbol: "VAI",
      address: "0x4BD17003473389A42DAF6a0a729f6Fdb328BbBd7",
      chainId: 56,
      decimals: 18,
      logoURI:
        "https://assets-cdn.trustwallet.com/blockchains/smartchain/assets/0x4BD17003473389A42DAF6a0a729f6Fdb328BbBd7/logo.png",
    },
    {
      name: "XRP Token",
      symbol: "XRP",
      address: "0x1D2F0da169ceB9fC7B3144628dB156f3F6c60dBE",
      chainId: 56,
      decimals: 18,
      logoURI:
        "https://assets-cdn.trustwallet.com/blockchains/smartchain/assets/0x1D2F0da169ceB9fC7B3144628dB156f3F6c60dBE/logo.png",
    },
    {
      name: "Venus",
      symbol: "XVS",
      address: "0xcF6BB5389c92Bdda8a3747Ddb454cB7a64626C63",
      chainId: 56,
      decimals: 18,
      logoURI:
        "https://assets-cdn.trustwallet.com/blockchains/smartchain/assets/0xcF6BB5389c92Bdda8a3747Ddb454cB7a64626C63/logo.png",
    },
  ],
};
