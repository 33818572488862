import { createContext, useState, useEffect } from "react";
import AeternaLottery from "../components/Lottery/AeternaLottery";
import HowtoPlay from "../components/Lottery/HowtoPlay";
import RecentDraws from "../components/Lottery/RecentDraws";
import LotteryRule from "../components/Lottery/LotteryRule";

export const TicketNumberContext = createContext();

export default function Lottery() {
  const [ticketCount, setTicketCount] = useState(0);

  return (
    <TicketNumberContext.Provider value={{ ticketCount, setTicketCount }}>
      <div className="container lg:container mx-auto">
        <AeternaLottery />
      </div>

      <RecentDraws />
      <div className="container lg:container mx-auto">
        <HowtoPlay />

        <LotteryRule />
      </div>
    </TicketNumberContext.Provider>
  );
}
