import { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MdArrowBack } from "react-icons/md";
import { Oval } from "react-loader-spinner";
import DatePagination from "../components/Lottery/DatePagination";
import TicketListCarousel from "../components/Lottery/TicketListCarousel";
import { getLotteryContract } from "../web3/getLotteryContract";
import { web3ModalContext } from "../components/Web3ModalProvider";

export default function PastTicket() {
  const { web3Modal, web3Data, setWeb3Data, connectWallet } = useContext(web3ModalContext);
  const navigate = useNavigate();
  const lotteryContract = getLotteryContract();
  const [lotteryList, setLotteryList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const connect = async () => {
    try {
      const { provider, library, signer, account, network, chainId } = await connectWallet();
      setWeb3Data({ ...web3Data, connected: true, provider, library, signer, account, network, chainId });
    } catch (err) {
      console.log(err);
    }
  };

  const pastTicket = async (lotteryID) => {
    try {
      const lotteryInfo = await lotteryContract.viewLottery(lotteryID);
      const userInfo = await lotteryContract.viewUserInfoForLotteryId(web3Data.account, lotteryID, 0, 1000);
      const lottery = {
        id: lotteryID,
        startTime: lotteryInfo.startTime,
        endTime: lotteryInfo.endTime,
        tickets: userInfo[1],
        ticketsStats: userInfo[2],
      };
      return lottery;
    } catch (err) {
      console.log(err);
    }
  };

  const getPastTickets = async () => {
    try {
      const lotteries = [];
      setIsLoading(true);
      const lotteryId = await lotteryContract.currentLotteryId();
      for (let i = 0; i <= lotteryId; i++) {
        const lottery = await pastTicket(i);
        if (lottery.tickets.length <= 0) continue;
        lotteries.push(lottery);
        console.log(lotteries);
      }
      setLotteryList(lotteries);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (web3Modal?.cachedProvider) {
      connect();
    }
  }, []);

  useEffect(() => {
    if (web3Data.connected) {
      if (web3Data.signer) getPastTickets();
    }
  }, [web3Data.connected]);

  return (
    <div className="container mx-auto">
      <div className="past-ticket">
        <button
          // to="/lottery"
          onClick={() => navigate(-1)}
          className="absolute flex items-center button text-white rounded-lg border border-white px-1 w-fit"
        >
          <MdArrowBack style={{ color: "#fff", marginRight: "4px" }} /> Back
        </button>
        <DatePagination>
          {isLoading ? (
            <div className="flex items-center justify-center my-20">
              <Oval
                height={48}
                width={48}
                color="#ffffff"
                wrapperStyle={{}}
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="transparent"
                strokeWidth={4}
                strokeWidthSecondary={4}
              />
            </div>
          ) : lotteryList.length > 0 ? (
            lotteryList.map((tickets, i) => <TicketListCarousel tickets={tickets} key={i} />)
          ) : (
            <h3>Nothing Tickets</h3>
          )}
        </DatePagination>
      </div>
    </div>
  );
}
