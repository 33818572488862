import TableHeader from "./TableHeader";
import TableBody from "./TableBody";

export default function LotteryRule() {
  return (
    <div className="lottery-rule">
      <h3>AETERNA LOTTERY RULE</h3>
      <div className="lottery-rule-container">
        <TableHeader />
        <TableBody />
      </div>
    </div>
  );
}
