import FlowLogo from "../../assets/images/dashboard/flow.png";

export default function PlayCard(props) {
  return (
    <div className={`playcard ${props.className}`}>
      {props.children}
      <img className="flow-logo" src={FlowLogo} alt="Flow Logo" />
    </div>
  );
}
