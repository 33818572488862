import { Link } from "react-router-dom";
import LotteryDashboard from "./LotteryDashboard";
import AeternaLogo from "../../assets/images/common/logo.png";
import TicketLogo from "../../assets/images/lottery/ticket.png";
import { getLotteryContract } from "../../web3/getLotteryContract";

export default function AeternaLottery() {
  return (
    <div className="aeterna-lottery">
      <LotteryDashboard />

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 mt-8">
        <div className="withdraw-card flex flex-row md:flex-col xl:flex-row justify-between items-center">
          <div className="flex flex-row items-center m-1">
            <img className="withdraw-img mr-2" src={AeternaLogo} alt="Aeterna Logo" />
            <div className="winning-value">
              <h4>Lottery Winnings</h4>
              <div className="value">
                <p>0.00</p>
                <img src={AeternaLogo} alt="Aeterna Logo" />
                <p>AETERNA</p>
              </div>
            </div>
          </div>
          <button className="withdraw-button m-1">Withdraw</button>
        </div>
        <div className="buy-ticket-card flex flex-row md:flex-col xl:flex-row justify-between items-center">
          <div className="flex flex-row items-center m-1">
            <img className="mr-4" src={TicketLogo} alt="Ticket Logo" />
            <div>
              <h4 className="mb-3">Your Tickets</h4>
              <h3>1</h3>
            </div>
          </div>
          <Link to="past/ticket" className="buy-ticket-button m-1">
            View Ticket
          </Link>
        </div>
        <div className="history-card flex flex-row md:flex-col xl:flex-row justify-between items-center">
          <img src={TicketLogo} alt="Ticket Logo" className="m-1" />
          <div className="flex justify-end md:justify-center flex-1">
            <Link to="list" className="history-button m-1">
              History
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
