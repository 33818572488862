import { useState, useEffect } from "react";

export default function Number(props) {
  const { picked, final, num, count, handleNumber } = props;

  return (
    <div
      className={`numeric flex justify-center items-center rounded-full border ${
        picked ? (final ? "picked final" : "picked") : ""
      }`}
      onClick={() => {
        if (picked === true) {
          handleNumber(num);
        } else {
          if (count >= 6) return;
          handleNumber(num);
        }
      }}
      style={{ width: "28px", height: "28px" }}
    >
      <p className="text-sm">{num}</p>
    </div>
  );
}
